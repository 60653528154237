import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import "../App.css";
import axiosimage from "../api/axiosimage"
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";

const LOGIN_URL = '/user/token';

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const userRef = useRef();
    const errRef = useRef();
    const [username, resetUsername, userAttribs] = useInput('username','');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);
    const loginData = {
        grant_type: '',
        username: username,
        password: pwd,
        client_id: '',
        client_secret: ''
    };

    // console.log("location.state: ", location.state);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosimage.post(LOGIN_URL, 
                new URLSearchParams(loginData)
            );
            const accessToken = response?.data?.access_token;
            const userRole1 = response?.data?.userRole1;
            const userRole2 = response?.data?.userRole2;
            setAuth({ userRole1, userRole2, accessToken });
            resetUsername();
            setPwd('');
            location.state?.from?.state?
                navigate(from, { state: location.state.from.state, replace: true }) 
                : navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
        
    }

    // const togglePersist = () => {
    //     setPersist(prev => !prev);
    // }

    // useEffect(() => {
    //     localStorage.setItem("persist", persist);
    // }, [persist])

    return(
        <>
            <div className="loginContainer">
                    <div className="loginBox-1">
                        <div style={{marginLeft:"10px", color:"red"}}>
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="loginBox-2">
                        <div style={{margin:"15px"}}>
                            <div>
                                <label htmlFor="username" style={{marginBottom:"7px"}}>아이디:</label>
                                <input 
                                    type="text" 
                                    id="username"
                                    placeholder="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    {...userAttribs}
                                    required
                                    style={{width:"100%"}}
                                />
                            </div>
                            <div style={{marginTop:"10px"}}>
                                <label htmlFor="password" style={{marginBottom:"7px", marginTop:"6px"}}>비밀번호:</label>
                                <input 
                                    type="password" 
                                    id="password"
                                    placeholder="password"
                                    onChange={(e) => setPwd(e.target.value)} 
                                    value={pwd}
                                    required
                                    style={{width:"100%"}}
                                />
                            </div>
                            <div style={{marginTop:"12px"}}>
                                <input 
                                    type="checkbox"
                                    id="persist"
                                    onChange={toggleCheck}
                                    value={check}
                                />
                                <label htmlFor="persist">로그인 유지</label>
                            </div>
                            <div style={{marginTop:"15px", textAlign:"center"}}>
                                <button className="button" style={{width:"200px", padding:"7px 15px"}}>로그인</button>
                            </div>
                        </div>
                    </form>
                    <div className="loginBox-3">
                        <div className="textGap">
                            <Link to="/forgotid">아이디 찾기</Link>
                            <Link to="/forgotpassword">비밀번호 찾기</Link>
                            <Link to="/freeagreement">회원가입</Link>
                        </div>
                        {/* <p>, 비밀번호 재설정, , 회원정보 관리, SNS로 로그인</p> */}
                    </div>
                </div>
        </>
    )
}

export default Login;