import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
import './mathsubject.css';
import '../App.css';
import GeoGebraMySolAnimation from '../components/GeoGebraMySolAnimation';

const MathSubjectMySolAnimation = ()=> {
    const {firstProbIDMy}=useParams();
    const {subjectCode, problemCode, subjectTitle, subject, problem, problemCategory, useStat, myAnswerList, myConstructionStep, myPointConstruction, myAnswerRaw, firstProbID, isSample} = useLocation().state;
    const navigateto=useNavigate();

    return(
        <div className="mathsubject" key={problem.problemCode}>
            <div className="subjectbox-1">
                <SideBar 
                    topCategory={subjectCode}
                    problemCate={problemCategory} 
                    categories={subject.categories}
                    problemID={problem.problemCode}
                    topCategoryTitle={subject.subjectTitle}
                    problemId={problem.problemCode}
                    isSample={isSample}
                />
            </div>
            <div className="subjectbox-2">
                <div className="problem" key={myAnswerRaw.answerMark}>
                    <div className="problemContentFit">
                        <h4>{myAnswerRaw.solvingTime.substring(6,10)}년 {myAnswerRaw.solvingTime.substring(3,5)}월 {myAnswerRaw.solvingTime.substring(0,2)}일 {myAnswerRaw.solvingTime.substring(12,17)}의 풀이</h4>
                        <GeoGebraMySolAnimation
                            idIn={problem.problemCode}
                            customToolBarIn={problem.customToolBar}
                            allowStyleBarIn={problem.allowStyleBar}
                            ggbBase64In={problem.problemContent}
                            myPointAnswer={myPointConstruction}
                            myAnswerStep={myConstructionStep}
                            problemType={problem.problemType}
                        />
                        <div className='problemmove'>
                            <button 
                                className='button'
                                onClick={() => isSample 
                                    ? navigateto(`/mathsubject/sample/${firstProbID}`, {state:{
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle
                                    }})
                                    : navigateto(`/mathsubject/${firstProbID}`, {state:{
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle
                                    }})
                                }
                            >
                                문제로 돌아가기
                            </button>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/mathsubject/my/${firstProbIDMy}`, {state:{
                                    "subjectCode": subjectCode,
                                    "problemCode": problemCode,
                                    "subjectTitle": subjectTitle,
                                    "subject":subject,
                                    "problem": problem,
                                    "problemCategory": problemCategory,
                                    "useStat": useStat,
                                    "myAnswerList": myAnswerList,
                                    "firstProbID": firstProbID,
                                    "isSample": isSample
                                }})}
                            >
                                풀이 최종 화면
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default MathSubjectMySolAnimation;