import React, { useState, useEffect } from "react";
import "./home.css"
import axiosapi from "../api/axiosapi";
import { Link } from "react-router-dom";
import SideMenu from "../components/SideMenu";
// import ProblemStatDescription from "../components/ProblemStatDescription";
import Footer from "../components/Footer";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const Home = () => {
    const [todayProblemList, setTodayProblemList] = useState([]);
    // const [dataList, setDataList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [imageProblem, setImageProblem] = useState();
    const [improveMathAbilityBeginner, setImproveMathAbilityBeginner] = useState([]);
    const [improveMathAbilityEasy, setImproveMathAbilityEasy] = useState([]);
    const [improveMathAbilityMedium, setImproveMathAbilityMedium] = useState([]);
    const [improveMathAbilityHard, setImproveMathAbilityHard] = useState([]);
    const [improveMathAbilityVeryHard, setImproveMathAbilityVeryHard] = useState([]);
    // const [subject, setSubject] = useState([]);
    const [errPT, setErrPT] = useState();
    // const [errTMA, setErrTMA] = useState();
    // const [errSub, setErrSub] = useState();
    const [err2, setErr2] = useState();
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    // const [randomString, setRandomString] = useState();
    // const [idString, setIdString] = useState('')


    useEffect(() => {
        const controller = new AbortController();

        const fetchTodayProblem = async () => {
            try {
                const response = await axiosapi.get('/home/hometodayproblem/')
                response.data && setTodayProblemList(response.data.todayProblemList);
                response.data && response.data.imageList.length > 0 && setImageList(response.data.imageList);
                response.data && setImageProblem(response.data.imageProblem);
            } catch (err) {
                setErrPT(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchTodayProblem();

        const fetchImproveAbility = async () => {
            try {
                const response2 = await axiosapi.get('/home/homeimproveability/')
                response2.data && setImproveMathAbilityBeginner(response2.data.beginner); 
                response2.data && setImproveMathAbilityEasy(response2.data.easy); 
                response2.data && setImproveMathAbilityMedium(response2.data.medium);
                response2.data && setImproveMathAbilityHard(response2.data.hard);
                response2.data && setImproveMathAbilityVeryHard(response2.data.veryHard);
            } catch (err2) {
                setErr2(err2);
            }
        }
        fetchImproveAbility();

        return () => controller.abort();
    },[])

    return(
        <div className="containerHome">
            <div className="boxHome-1">
                <h4 style={{color:"sienna", fontWeight:"bold"}}>도전!!! 작도 퀴즈</h4>
                <p>작도 퀴즈와 함께 당신의 잠자고 있는 뇌를 깨우세요. 목표가 되는 도형을 상상하고, 직관을 통해 구체화하고, 기하 지식과 논리를 통해 체계화해 작도 퀴즈를 해결하는 짜릿한 지적 쾌감을 느껴보세요.</p>
            </div>

            <div className="boxHome-2" style={{marginBottom:"15px"}}>
                <div className="homeimproveability">
                    <div className="homeimprove">
                        {improveMathAbilityBeginner.slice(0,1).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityBeginner,
                                            "improveAbilityType": "beginner"
                                        }}
                                    >
                                        <img src={`/image/beginner.png`} style={{width:'100%'}} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="homeimprove" style={{marginLeft:"15px"}}>
                        {improveMathAbilityBeginner.slice(1,2).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="mt-1 mb-3">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityBeginner,
                                            "improveAbilityType": "beginner"
                                        }}
                                    >
                                        <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                        <p style={{marginLeft:"15px", color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div style={{marginTop:"20px"}}>
                            <Link 
                                to="/improvemathability"
                                state={{
                                    "improveAbilityType": "beginner"
                                }}
                            >
                                더 보기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-3" style={{marginBottom:"15px"}}>
                <div className="homeimproveability">
                    <div className="homeimprove">
                        {improveMathAbilityEasy.slice(0,1).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityEasy,
                                            "improveAbilityType": "easy"
                                        }}
                                    >
                                        <img src={`/image/easy.png`} style={{width:'100%'}} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="homeimprove" style={{marginLeft:"15px"}}>
                        {improveMathAbilityEasy.slice(1,2).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="mt-1 mb-3">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityEasy,
                                            "improveAbilityType": "easy"
                                        }}
                                    >
                                        <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                        <p style={{marginLeft:"15px", color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div style={{marginTop:"20px"}}>
                            <Link 
                                to="/improvemathability"
                                state={{
                                    "improveAbilityType": "easy"
                                }}
                            >
                                더 보기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-4" style={{marginBottom:"15px"}}>
                <div className="homeimproveability">
                    <div className="homeimprove">
                        {improveMathAbilityMedium.slice(0,1).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityMedium,
                                            "improveAbilityType": "medium"
                                        }}
                                    >
                                        <img src={`/image/medium.png`} style={{width:'100%'}} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="homeimprove" style={{marginLeft:"15px"}}>
                        {improveMathAbilityMedium.slice(1,2).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="mt-1 mb-3">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityMedium,
                                            "improveAbilityType": "medium"
                                        }}
                                    >
                                        <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                        <p style={{marginLeft:"15px", color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div style={{marginTop:"20px"}}>
                            <Link 
                                to="/improvemathability"
                                state={{
                                    "improveAbilityType": "medium"
                                }}
                            >
                                더 보기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-5" style={{marginBottom:"15px"}}>
                <div className="homeimproveability">
                    <div className="homeimprove">
                        {improveMathAbilityHard.slice(0,1).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityHard,
                                            "improveAbilityType": "hard"
                                        }}
                                    >
                                        <img src={`/image/hard.png`} style={{width:'100%'}} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="homeimprove" style={{marginLeft:"15px"}}>
                        {improveMathAbilityHard.slice(1,2).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="mt-1 mb-3">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityHard,
                                            "improveAbilityType": "hard"
                                        }}
                                    >
                                        <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                        <p style={{marginLeft:"15px", color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div style={{marginTop:"20px"}}>
                            <Link 
                                to="/improvemathability"
                                state={{
                                    "improveAbilityType": "hard"
                                }}
                            >
                                더 보기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-6" style={{marginBottom:"15px"}}>
                <div className="homeimproveability">
                    <div className="homeimprove">
                        {improveMathAbilityVeryHard.slice(0,1).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityVeryHard,
                                            "improveAbilityType": "veryHard"
                                        }}
                                    >
                                        <img src={`/image/veryhard.png`} style={{width:'100%'}} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div className="homeimprove" style={{marginLeft:"15px"}}>
                        {improveMathAbilityVeryHard.slice(1,2).map(improveAbility => {
                            return(
                                <div key={improveAbility.id} className="mt-1 mb-3">
                                    <Link
                                        to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                        state={{
                                            "categoryCode": improveAbility.categoryCode,
                                            "problemCode": improveAbility.firstProblemCode,
                                            "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                            "improveAbilityList": improveMathAbilityVeryHard,
                                            "improveAbilityType": "veryHard"
                                        }}
                                    >
                                        <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                        <p style={{marginLeft:"15px", color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                    </Link>
                                </div>
                            )
                        })}
                        <div style={{marginTop:"20px"}}>
                            <Link 
                                to="/improvemathability"
                                state={{
                                    "improveAbilityType": "veryHard"
                                }}
                            >
                                더 보기
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-7" style={{marginTop:"15px"}}>
                <h4 style={{color:"sienna", fontWeight:"bold"}}>즐거운 작도 세상</h4>
                <p>작도 문제를 해결하고 숨겨진 생각을 그림으로 발산하세요. 솔직하고 어설픈 그림도 크게 들린답니다.</p>
                {todayProblemList.slice(0,1).map(todayProblem => {
                    return(
                        <div key={todayProblem.id}>
                            <Link 
                                to={`/todayproblem/${todayProblem.urlCode}`}
                                state={{
                                    "todayProblemList": todayProblemList,
                                    "todayProblem": todayProblem,
                                    // "imageList": imageList
                                }}
                            >
                                <div style={{textAlign:"center"}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                                <img src={`/image/${todayProblem.imageFile}`} style={{width:'100%', backgroundColor:`${todayProblem.backgroundColor}`}} />
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className="boxHome-8">
                <h5 style={{marginTop:'25px', marginBottom:'18px', marginLeft:'8px', fontWeight:"bold"}}>그림이 와글와글</h5>
                {imageList.map(image => {
                    return(
                        <div key={image.id}>
                            <Link 
                                to={`/viewimage/${image.imageFile}/${image.backgroundColor}`}
                                // state={{
                                //     "imageFileName": image.imageFile,
                                //     "imageBackgroundColor": image.backgroundColor
                                // }}
                                target="_blank"
                            >
                                <p style={{marginLeft:'10px'}}>{image.coloringTitle}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className="boxHome-9">
                <h5 style={{marginTop:'25px', marginBottom:'18px', fontWeight:"bold"}}>다른 작도 문제</h5>
                {todayProblemList.slice(1,todayProblemList.length).map(todayProblem => {
                    return (
                        <div key={todayProblem.id}>
                            <Link 
                                to={`/todayproblem/image/${todayProblem.urlCode}`}
                                state={{
                                    // "todayProblemList": todayProblemList,
                                    "todayProblem": todayProblem
                                }}
                            >
                                <div style={{marginLeft:'2px'}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className="boxHome-10">
                <div>
                    <SideMenu />
                </div>
            </div>

            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    );
}

export default Home;
