import React, { useEffect, useState } from 'react';
import GeoGebraMySol from '../components/GeoGebraMySol';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import './mathsubject.css';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ForTeacherMenu from '../components/ForTeacherMenu';

const TeacherMyAnswer = ()=> {
    const {teacherMyAnswerMark}=useParams();
    const navigateto = useNavigate();
    const {myAnswer, myAnswerList} = useLocation().state;
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [myConstruct, setMyConstruct] = useState('');
    const [flag, setFlag] =useState('');
    const [myAnswerRaw, setMyAnswerRaw] = useState('');
    const [myConstructionStep, setMyConstructionStep] = useState([]);
    const [myPointConstruction, setMyPointComstruction] = useState([]);
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [myAnswerPicked, setMyAnswerPicked] = useState('');

    const handleSelect = (e) => {
        setSelectedAnswer(e.target.value);
    }

    useEffect(() => {
        setMyConstruct(myConstructionStep);
        setFlag(teacherMyAnswerMark);
    },[myConstructionStep])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const answerPicked = myAnswerList.find((item) => item.answerMark === teacherMyAnswerMark)
        setMyAnswerPicked(answerPicked);

        const fetchMyAnswers = async () => {

            try {
                const response = await axiosPrivate.get('/solve/myanswer/problemsolving', {
                    signal: controller.signal,
                    params: {
                        answerMark: teacherMyAnswerMark,
                        problemCode: answerPicked.problemCode
                    }
                });

                isMounted && response.data && setMyAnswerRaw(response.data);

                const myConstructStep = response.data && response.data.solvingContent.split("|");
                setMyConstructionStep(myConstructStep);

                const myPointConstruct = response.data && response.data.solvingPoint.split("|");
                setMyPointComstruction(myPointConstruct);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            } finally {
                setLoading(false);
            }
        }

        fetchMyAnswers();
        
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [myAnswer])
    
    return(
        <div className="mathsubject">
            <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForTeacherMenu />
				</div>
			</div>
            <div className="subjectbox-2" style={{marginTop:"14px"}}>
                <div className="problem" key={myAnswerRaw.answerMark}>

                    <div className="problemContentFit">
                        <div>
                            <select value={selectedAnswer} style={{height:"36px", width:"60%"}} onChange={handleSelect}>
                                <option value>문제를 푼 때를 선택하세요.</option>
                                {myAnswerList.map(myAns => {
                                    return <option value={myAns.answerMark} key={myAns.answerMark}>
                                        {myAns.problemTitle} | {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                                    </option>
                                })}
                            </select>
                            {console.log('selectedAnswer: ', selectedAnswer)}
                            {console.log('myAnswerPicked: ', myAnswerPicked)}
                            <button 
                                className='button' 
                                style={{marginLeft:"10px"}}
                                onClick={() => navigateto(`/teacher/myinfo/answer/item/${selectedAnswer}`, {state: {
                                    "myAnswer": myAnswerPicked,
                                    "myAnswerList": myAnswerList
                                }})}
                            >
                                풀이 보기
                            </button>
                        </div>
                        <div key={flag}>
                            {myAnswerRaw && myConstruct.length !== 0 
                                ? <GeoGebraMySol
                                        idIn={myAnswerRaw.problemCode}
                                        customToolBarIn={myAnswerRaw.customToolBar}
                                        allowStyleBarIn={myAnswerRaw.allowStyleBar}
                                        ggbBase64In={myAnswerRaw.problemContent}
                                        myAnswerStep={myConstruct}
                                        myPointAnswer={myPointConstruction}
                                        problemType={myAnswerRaw.problemType}
                                    />
                                : "loading"
                            }
                        </div>
                        <div className='problemmove'>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/myinfo/answer/list/`)}
                            >
                                문제 풀이 목록
                            </button>
                            {(myAnswerRaw.problemType === 1 || myAnswerRaw.problemType === 2)
                                ? <button 
                                        className='button'
                                        onClick={() => navigateto(`/teacher/myinfo/answer/item/animation/${teacherMyAnswerMark}`, {state:{
                                            "myAnswer": myAnswer,
                                            "myAnswerList": myAnswerList,
                                            "myConstructionStep": myConstructionStep,
                                            "myPointConstruction": myPointConstruction,
                                            "myAnswerRaw": myAnswerRaw
                                        }})}
                                    >
                                        풀이 과정 보기
                                    </button>
                                : null
                            }
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default TeacherMyAnswer;