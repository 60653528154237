import { React, useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import "./home.css"
import axiosapi from "../api/axiosapi";
import Footer from "../components/Footer";

const ExerciseCalculationList = () => {
    const [exerciseCalculationList, setExerciseCalculationList] = useState([]);
    const [sampleExerciseCalculationList, setSampleExerciseCalculationList] = useState([]);
    const [err, setErr] = useState();

    useEffect(() => {
        const controller = new AbortController();

        const fetchExerciseList = async() => {
            try {
                const response = await axiosapi.get('/category/calculating/');
                setExerciseCalculationList(response.data);
            } catch (err) {
                setErr(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        };

        const fetchSampleCalculationList = async () => {
            try {
                const response2 = await axiosapi.get('/sample/category/calculation/')
                response2.data && setSampleExerciseCalculationList(response2.data);
            } catch(err) {
                console.log(err)
            }
        }
        
        fetchExerciseList();
        fetchSampleCalculationList();

        return () => controller.abort();
    },[])

    return(
        <div className="containerHome">
            <div className="boxHome-1">
                <div className="gradeTitle">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>연산 연습 샘플</h4>
                </div>
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="improveAbilityCards">
                            {sampleExerciseCalculationList && sampleExerciseCalculationList.map( category => {
                                return(
                                    <div key={category.id} className="improveAbilityCard">
                                        <h5>{category.sampleCategoryTitle}</h5>
                                        <p>{category.categoryDescription}</p>
                                        <Link 
                                            to={`/calculationexercise/sample/` }
                                            state={{
                                                "categoryCode": category.categoryCode,
                                                "problemCode": category.firstProblemCode,
                                                "categoryTitle": category.sampleCategoryTitle
                                            }}
                                        >
                                            연산 연습하기
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="boxHome-3">
                <div className="gradeTitle">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>연산 연습</h4>
                </div>
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="improveAbilityCards">
                            {exerciseCalculationList.map((calcul) => {
                                    return (
                                        <div key={calcul.id} className="improveAbilityCard">
                                            <h4>{calcul.categoryTitle}</h4>
                                            <p>{calcul.categoryDescription}</p>
                                            {/* <p>{calcul.tryNumber === 0
                                                ? `아무도 도전한 사람이 없습니다. ${calcul.problems.length}개의 문제를 모두 해결하는 첫 번째 도전자가 되시기 바랍니다.`
                                                : calcul.successNumber === 0
                                                    ? `${calcul.problems.length}개의 문제를 모두 해결한 사람이 아무도 없습니다. 모든 문제를 해결하는 첫 번째 도전자가 되시기 바랍니다.`
                                                    : `${calcul.tryNumber}명 중 ${calcul.successNumber}명이 ${calcul.problems.length}개의 문제를 모두 풀었습니다. 문제를 모두 풀어낸 ${(calcul.successNumber/calcul.tryNumber*100).toFixed(2)}%의 사람들과 같은 자리를 차지하세요.`
                                            }</p> */}
                                            <Link 
                                                to={`/calculationexercise/`}
                                                state={{
                                                    "categoryCode": calcul.categoryCode,
                                                    "categoryTitle": calcul.categoryTitle,
                                                    "problemCode": calcul.firstProblemCode
                                                }}
                                            >
                                                연산 연습하기
                                            </Link>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    );
}

export default ExerciseCalculationList;