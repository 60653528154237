import React from 'react';
import './mathsubject.css';
import ForStudentMenu from '../components/ForStudentMenu';

const StudentPage = () => {

    return(
        <div className="subjectbox-1">
            <div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                <ForStudentMenu />
            </div>
        </div>
    )
}

export default StudentPage;