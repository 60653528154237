import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './mathsubject.css';
import ForTeacherMenu from "../components/ForTeacherMenu";
import ViewMyAnswerList from "../components/ViewMyAnswerList";

const TeacherMyAnswerList = () => {
       return (
              <div className="mathsubject">
                     <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForTeacherMenu />
				</div>
			</div>
                     <div className="subjectbox-2" style={{marginTop:"14px"}}>
                            <ViewMyAnswerList />
                     </div>
              </div>
       )
}

export default TeacherMyAnswerList;