import React from 'react';
import useAuth from '../hooks/useAuth';
import ForTeacherMenu from '../components/ForTeacherMenu';
import './mathsubject.css';
import '../App.css';

const TeacherPage = () => {
    const {auth} = useAuth();

    if (auth?.userRole1 !== "teacher") {
        return(
            <div className="problem">
                <p>티프매쓰에 선생님으로 등록하신 분들만 사용할 수 있습니다. 티프매쓰 선생님으로 등록 신청을 하세요.</p>
            </div>
        )
    }
    return(
        <div className="subjectbox-1">
            <div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                <ForTeacherMenu />
            </div>
        </div>
    )
}

export default TeacherPage;