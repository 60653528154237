import React, {useState, useEffect} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
import './mathsubject.css';
import '../App.css';
import GeoGebraMySol from '../components/GeoGebraMySol';
import ViewMyAnswerItem from '../components/ViewMyAnswerItem';

const MathSubjectMy = ()=> {
    const {firstProbIDMy}=useParams();
    const {subjectCode, problemCode, subjectTitle, subject, problem, problemCategory, useStat, myAnswerList, firstProbID, isSample} = useLocation().state;
    const {myConstructionStep, myAnswerRaw, myPointConstruction, errAns} = ViewMyAnswerItem({"answerMark": firstProbIDMy});
    const navigateto=useNavigate();
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [myConstruct, setMyConstruct] = useState('');
    const [flag, setFlag] =useState('');
    const location = useLocation();

    useEffect(() => {
        setMyConstruct(myConstructionStep);
        setFlag(firstProbIDMy);
    },[myConstructionStep])

    return(
        <div className="mathsubject" key={problem.problemCode}>
            <div className="subjectbox-1">
                <SideBar 
                    topCategory={subjectCode}
                    problemCate={problemCategory} 
                    categories={subject.categories}
                    problemID={problem.problemCode}
                    topCategoryTitle={subject.subjectTitle}
                    problemId={problem.problemCode}
                    isSample={isSample}
                />
            </div>
            <div className="subjectbox-2">
                <div className="problem"  key={myAnswerRaw.answerMark}>
                    {errAns 
                        ? navigateto("/login", {state:{"from": location}}, {replace: true})
                        : <div className="problemContentFit">
                            <h4>{myAnswerRaw && `${myAnswerRaw.solvingTime.substring(6,10)}년 ${myAnswerRaw.solvingTime.substring(3,5)}월 ${myAnswerRaw.solvingTime.substring(0,2)}일 ${myAnswerRaw.solvingTime.substring(12,17)}의 풀이`}</h4>
                            <div>
                                <select value={selectedAnswer} style={{height:"36px"}} onChange={(e) => setSelectedAnswer(e.target.value)}>
                                    <option value>문제를 푼 때를 선택하세요.</option>
                                    {myAnswerList.map(myAns => {
                                        return <option value={myAns.answerMark} key={myAns.answerMark}>
                                            {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                                        </option>
                                    })}
                                </select>
                                <button 
                                    className='button' 
                                    style={{marginLeft:"10px"}}
                                    onClick={() => navigateto(`/mathsubject/my/${selectedAnswer}`, {state: {
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle,
                                        "subject":subject,
                                        "problem": problem,
                                        "problemCategory": problemCategory,
                                        "useStat": useStat,
                                        "myAnswerList": myAnswerList,
                                        "firstProbID": firstProbID,
                                        "isSample": isSample
                                    }})}
                                >
                                    풀이 보기
                                </button>
                            </div>
                            <div key={flag}>
                                {myConstruct.length !== 0
                                    ? <GeoGebraMySol
                                            idIn={problem.problemCode}
                                            customToolBarIn={problem.customToolBar}
                                            allowStyleBarIn={problem.allowStyleBar}
                                            ggbBase64In={problem.problemContent}
                                            myPointAnswer={myPointConstruction}
                                            myAnswerStep={myConstruct}
                                            problemType={problem.problemType}
                                        />
                                    : "Loading"
                                }
                            </div>
                            <div className='problemmove'>
                                <button 
                                    className='button'
                                    onClick={() => isSample 
                                        ? navigateto(`/mathsubject/sample/${firstProbID}`, {state:{
                                            "subjectCode": subjectCode,
                                            "problemCode": problemCode,
                                            "subjectTitle": subjectTitle
                                        }})
                                        : navigateto(`/mathsubject/${firstProbID}`, {state:{
                                            "subjectCode": subjectCode,
                                            "problemCode": problemCode,
                                            "subjectTitle": subjectTitle
                                        }})
                                    }
                                >
                                    문제로 돌아가기
                                </button>
                                {(problem.problemType === 1 || problem.problemType === 2)
                                    ? <button 
                                            className='button'
                                            onClick={() => navigateto(`/mathsubject/my/animation/${firstProbIDMy}`, {state:{
                                                "subjectCode": subjectCode,
                                                "problemCode": problemCode,
                                                "subjectTitle": subjectTitle,
                                                "subject":subject,
                                                "problem": problem,
                                                "problemCategory": problemCategory,
                                                "useStat": useStat,
                                                "myAnswerList": myAnswerList,
                                                "myConstructionStep": myConstructionStep,
                                                "myPointConstruction": myPointConstruction,
                                                "myAnswerRaw": myAnswerRaw,
                                                "firstProbID": firstProbID,
                                                "isSample": isSample
                                            }})}
                                        >
                                            풀이 과정 보기
                                        </button>
                                    : null
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <br/>
        </div>
    );
};

export default MathSubjectMy;