import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import axiosapi from "../api/axiosapi";
import "./home.css";
import Footer from "../components/Footer";

const ImproveMathAbilityList = () => {
    const {improveAbilityType} = useLocation().state;
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    const [improveAbilityList, setImproveMathAbilityList] = useState([]);

    useEffect(() => {
        const getImproveAbilityList = async () => {
            try {
                const response = await axiosapi.get('/home/improveability/'+improveAbilityType);
                response.data && setImproveMathAbilityList(response.data);
            } catch (err) {
                console.log(err);
            }
        }

        getImproveAbilityList();
    },[])

    return(
        <div className="containerHome">
            <div className="boxHome-1" style={{marginBottom:"20px"}}>
                {improveAbilityType === "beginner" 
                    ? <p className="title">왕초보를 위한 작도 퀴즈 List</p>
                    : improveAbilityType === "easy"
                        ? <p className="title">작도 초보를 위한 작도 퀴즈 List</p>
                        : improveAbilityType === "medium"
                            ? <p className="title">조금 알 것 같은 중급자를 위한 작도 퀴즈 List</p>
                            : improveAbilityType === "hard"
                                ? <p className="title">제법 아는 사람을 위한 작도 퀴즈 List</p>
                                : improveAbilityType === "veryHard" && <p className="title">어려운 문제에 도전하고픈 사람을 위한 작도 퀴즈 List</p>
                }
                
            </div>
            <div className="boxHome-2">
                {improveAbilityList && improveAbilityList.map((improveAbility) => {
                    return (
                        <div key={improveAbility.id} style={{marginBottom:"15px"}}>
                            {/* <p>{improve.tryNumber === 0
                                ? `아무도 도전한 사람이 없습니다. ${improve.problems.length}개의 문제를 모두 해결하는 첫 번째 도전자가 되시기 바랍니다.`
                                : improve.successNumber === 0
                                    ? `${improve.problems.length}개의 문제를 모두 해결한 사람이 아무도 없습니다. 모든 문제를 해결하는 첫 번째 도전자가 되시기 바랍니다.`
                                    : `${improve.tryNumber}명 중 ${improve.successNumber}명이 ${improve.problems.length}개의 문제를 모두 풀었습니다. 문제를 모두 풀어낸 ${(improve.successNumber/improve.tryNumber*100).toFixed(2)}%의 사람들과 같은 자리를 차지하세요.`
                            }</p> */}
                            <Link 
                                to={`/improvemathability/${improveAbility.categoryCode}${improveAbility.urlAdd}`}
                                state={{
                                    "categoryCode": improveAbility.categoryCode,
                                    "problemCode": improveAbility.firstProblemCode,
                                    "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                    "improveAbilityList": improveAbilityList,
                                    "improveAbilityType": improveAbilityType
                                }}
                            >
                                <h5>{improveAbility.improveAbilityTitle}</h5>
                            </Link>
                            <p>{improveAbility.improveAbilityDescription}</p>
                        </div>
                    )
                })}
            </div>
            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    );
}

export default ImproveMathAbilityList;