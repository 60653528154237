import React from "react";
import './mathsubject.css';
import PurchaseHistory from "../components/PurchaseHistory";
import ForTeacherMenu from "../components/ForTeacherMenu";

const TeacherPurchaseHistory = () => {
       return (
              <div className="mathsubject">
                     <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForTeacherMenu />
				</div>
			</div>
                     <div className="subjectbox-2" style={{marginTop:"14px"}}>
                            <PurchaseHistory />
                     </div>
              </div>
       )
}

export default TeacherPurchaseHistory;