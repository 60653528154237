import React, {useState, useEffect, useRef} from "react";
import axiosapi from "../api/axiosapi";
import { useLocation, useNavigate } from "react-router-dom";
import './signup.css';

const ForgotPwdSecond = () => {
	const {username} = useLocation().state;
	const [password, setPassword] = useState('');
	const [confirmPwd, setConfirmPwd] = useState('');
	const [passwordError, setPasswordError] = useState(false);
    const [confirmPwdError, setConfirmPwdError] = useState(false);
	const navigateto = useNavigate();

	const onPasswordHandler = (e) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*+=-_])(?=.*[0-9]).{8,25}$/

        if (!e.target.value || !passwordRegex.test(e.target.value)) setPasswordError(true);
        else setPasswordError(false)

        setPassword(e.target.value)
    }

    const onConfirmPwdHandler = (e) => {
        if (e.target.value === password) setConfirmPwdError(false);
        else setConfirmPwdError(true);
        
        setConfirmPwd(e.target.value)
    }

	const onSubmitHandler = async (e) => {
        e.preventDefault();

        if ( passwordError) return (alert("비밀번호는 영문자+숫자+특수문자 조합으로 8자리 이상이어야 합니다."))
        
        if ( password !== confirmPwd)  
            return (alert("비밀번호와 비밀번호 확인은 같아야 합니다.")) 

        try {
            const response = await axiosapi.post('/user/resetpassword', 
                {
                    username: username,
                    password: password,
                },
                {
                    headers: {'Content-Type': 'application/JSON'},
                    withCredentials: true
                }
            );
            console.log(response.data);
            if (response.status === 200) {
                alert(username+"님의 비밀번호가 변경되었습니다.");
                navigateto("/login");
            }
        } catch (err) {
            console.log('비밀번호 변경 에러:', err.data)
        }
    }

	return(
        <div className="forgotIdContainer">
            <div className="forgotIdBox-1">
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="resgisteredemail-tab" data-bs-toggle="tab" data-bs-target="#resgisteredemail" type="button" role="tab" aria-controls="resgisteredemail" aria-selected="true">이메일로 찾기</button>
                    </li>
                </ul> */}
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="resgisteredemail" role="tabpanel" aria-labelledby="home-tab">
                        <div>
                            <form onSubmit={onSubmitHandler}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
										<label className="form-label">비밀번호</label>
										<input type="password" className="form-control" value={password} onChange={onPasswordHandler} required />
                                    </div>
									<div>
										{!password
											? <span style={{fontSize:"0.8rem", color:"black"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
											: passwordError 
												? <span style={{fontSize:"0.8rem", color:"red"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
												: <span style={{fontSize:"0.8rem", color:"blue"}}>사용가능한 비밀번호입니다.</span>
										}
									</div>
                                    <div className="labeledItem">
										<label className="form-label">비밀번호 확인</label>
										<input type="password" className="form-control" value={confirmPwd} onChange={onConfirmPwdHandler} required />
										{!confirmPwd
											? <span style={{fontSize:"0.8rem", color:"black"}}>비밀번호를 한 번 더 입력하세요.</span>
											: confirmPwdError
												? <span style={{fontSize:"0.8rem", color:"red"}}>비밀번호가 일치하지 않습니다.</span>
												: <span style={{fontSize:"0.8rem", color:"blue"}}>비밀번호가 일치합니다.</span>
										}
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"140px", padding:"7px 15px"}}>비밀번호 변경</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPwdSecond;