import React, {useState, useEffect, useRef} from "react";
import axiosapi from "../api/axiosapi";
import DaumPostCode from "react-daum-postcode";
import { useLocation, useNavigate } from "react-router-dom";
import './signup.css';

const SignUp = () => {
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [doubleCheck, setDoubleCheck] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [address, setAddress] = useState('');
    const [detailAddress, setDetailAddress] = useState('');
    const [birthYear, setBirthYear] = useState();
    const [nation, setNation] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPwdError, setConfirmPwdError] = useState(false);
    const [error, setError] = useState({});
    const [errMsg, setErrorMsg] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const agreeFreeMembershipAgreement = location.state.agreeFM;
    const agreePrivacyPolicy = location.state.agreePP;
    const navigate = useNavigate();

    const onUsernameHandler = (e) => {
        setUsername(e.target.value)
    }

    const onDoubleCheckHandler = async (event) => {
        event.preventDefault();
        if (username === '') alert("사용자 아이디를 입력해 주세요!");
        else {
            try {
                const response = await axiosapi.get('/user/usernamedoublecheck/'+username)
                console.log(response.data);
                if(response.data === false) {
                    alert(username+"는 사용가능한 아이디입니다.");
                    setDoubleCheck(true);
                } else {
                    alert(username+"는 이미 사용중인 아이디입니다.");
                    setDoubleCheck(false);
                }
            } catch (err) {
                console.log('아이디 중복 체크', err.data)
            }
        }
        
    }
 
    const onPasswordHandler = (e) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*+=-_])(?=.*[0-9]).{8,25}$/

        if (!e.target.value || !passwordRegex.test(e.target.value)) setPasswordError(true);
        else setPasswordError(false)

        setPassword(e.target.value)
    }

    const onConfirmPwdHandler = (e) => {
        if (e.target.value === password) setConfirmPwdError(false);
        else setConfirmPwdError(true);
        
        setConfirmPwd(e.target.value)
    }

    const onNameHandler = (e) => {
        setName(e.target.value)
    } 

    const onEmailHandler = (e) => {
        setEmail(e.target.value)
    }

    const onMobileHandler = (e) => {
        setMobile(e.target.value)
    }

    const onBirthYearHandler = (e) => {
        setBirthYear(e.target.value)
    }

    // 팝업창 열기
    const openZipcode = () => {
        setIsPopupOpen(true);
    }
 
    // zipcode
    const handleAddress = (data) => {
        let fullAddr = data.address;
        let extraAddr = '';

        if (data.addressType === 'R') {
        if (data.bname !== '') {
            extraAddr += data.bname;
        }
        if (data.buildingName !== '') {
            extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        setAddress(fullAddr)
        setZipCode(data.zonecode)
        setIsPopupOpen(false)
        setDetailAddress('')
    };
        
    // DaumPostCode style
    const width = 400;
    const height = 500;
    const modalStyle = {
      position: "relative",
      top: 10,
      border: "1px solid #000000",
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if ( !doubleCheck ) {
            setDoubleCheck(false);
            alert("사용자 아이디 중복확인이 필요합니다.");
            return;
        }

        if ( passwordError) return (alert("비밀번호는 영문자+숫자+특수문자 조합으로 8자리 이상이어야 합니다."))
        
        if ( password !== confirmPwd)  
            return (alert("비밀번호와 비밀번호 확인은 같아야 합니다.")) 

        try {
            const response = await axiosapi.post('/user/signup', 
                {
                    username: username,
                    password: password,
                    name: name,
                    lastname: "",
                    nation: "",
                    email: email,
                    mobile: mobile,
                    phoneNumber: mobile,
                    zipcode: zipCode,
                    address: address,
                    detailAddress: detailAddress,
                    agreeFreeMembershipAgreement: agreeFreeMembershipAgreement,
                    agreePrivacyPolicy: agreePrivacyPolicy,
                    birthYear: birthYear
                },
                {
                    headers: {'Content-Type': 'application/JSON'},
                    withCredentials: true
                }
            );
            console.log(response.data);
            if (response.status === 201) {
                alert(username+"님의 회원가입이 완료되었습니다.");
                navigate("/login");
            }
        } catch (err) {
            console.log('회원가입 에러:', err.data)
        }
    }

    return(
        <div className="signupContainer">
            <div className="signupBox">
                <div className="container m-80 p-20">
                    <form className="row g-3" onSubmit={onSubmitHandler}>
                        <div className="signupListing">
                            <div className="signupBox-1">
                                <div className="col-md-5">
                                    <label className="form-label">사용자 아이디</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" value={username} onChange={onUsernameHandler} required /> 
                                        <button className="btn btn-secondary" type="button" onClick={onDoubleCheckHandler}>중복확인</button>
                                    </div>
                                </div>
                            </div>
                            <div className="signupBox-2">
                                <div className="col-md-6">
                                    <label className="form-label">비밀번호</label>
                                    <input type="password" className="form-control" value={password} onChange={onPasswordHandler} required />
                                </div>
                                <div>
                                    {!password
                                        ? <span style={{fontSize:"0.8rem", color:"black"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
                                        : passwordError 
                                            ? <span style={{fontSize:"0.8rem", color:"red"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
                                            : <span style={{fontSize:"0.8rem", color:"blue"}}>사용가능한 비밀번호입니다.</span>
                                    }
                                </div>
                            </div>
                            <div className="signupBox-3">
                                <div className="col-md-6">
                                    <label className="form-label">비밀번호 확인</label>
                                    <input type="password" className="form-control" value={confirmPwd} onChange={onConfirmPwdHandler} required />
                                    {!confirmPwd
                                        ? <span style={{fontSize:"0.8rem", color:"black"}}>비밀번호를 한 번 더 입력하세요.</span>
                                        : confirmPwdError
                                            ? <span style={{fontSize:"0.8rem", color:"red"}}>비밀번호가 일치하지 않습니다.</span>
                                            : <span style={{fontSize:"0.8rem", color:"blue"}}>비밀번호가 일치합니다.</span>
                                    }
                                </div>
                            </div>
                            <div className="signupBox-4">
                                <div className="col-md-7">
                                    <label className="form-label">이름</label>
                                    <input type="text" className="form-control" value={name} onChange={onNameHandler} required />
                                </div>
                            </div>
                            <div className="signupBox-5">
                                <div className="col-md-7">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" value={email} onChange={onEmailHandler} required />
                                </div>
                            </div>
                            <div className="signupBox-6">
                                <div className="col-md-7">
                                    <label className="form-label">휴대폰 / 전화</label>
                                    <input type="mobile" className="form-control" value={mobile} onChange={onMobileHandler} required />
                                </div>
                            </div>
                            <div className="signupBox-7">
                                <div className="col-7">
                                    <label className="form-label">주소</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" value={zipCode} onClick={openZipcode} placeholder="우편번호" readOnly />
                                        <button className="btn btn-secondary" type="button" onClick={openZipcode}>우편번호 검색</button>
                                        {isPopupOpen ? (
                                            <DaumPostCode
                                            onComplete={handleAddress}
                                            autoClose
                                            width={width}
                                            height={height}
                                            style={modalStyle}
                                            isDaumPost={isPopupOpen}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-10 mt-1">
                                    <input type="text" className="form-control" value={address} placeholder="주소" readOnly />
                                </div>
                                <div className="col-10 mt-1">
                                    <input type="text" className="form-control" placeholder="상세주소" 
                                        value={detailAddress} onChange={(e)=>{setDetailAddress(e.target.value)}} />
                                </div>
                            </div>
                            <div className="signupBox-8">
                                <div className="col-md-7">
                                    <label className="form-label">출생연도</label>
                                    <input type="birthYear" className="form-control" value={birthYear} onChange={onBirthYearHandler} required />
                                </div>
                            </div>
                            <div className="signupBox-10">
                                <div className="col-8">
                                    <button type="submit" className="btn btn-success">회원가입</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUp;
