import axiosapi from "../api/axiosapi";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import Footer from "../components/Footer";

const GradeTotalListTextbook = () => {
    const [textbookList, setTextbookList] = useState([]);
    const [sampleTextbookList, setSampleTextbookList] = useState([]);
    const [randomString, setRandomString] = useState();

    useEffect(() => {
        const fetchTextbookList = async () => {
            try {
                const response = await axiosapi.get('/phase/textbook/list')
                response.data && setTextbookList(response.data);
            } catch(err) {
                console.log(err)
            }
        }

        const fetchSampleTextbookList = async () => {
            try {
                const response2 = await axiosapi.get('/sample/subject/textbook/')
                response2.data && setSampleTextbookList(response2.data);
            } catch(err) {
                console.log(err)
            }
        }

        fetchTextbookList();
        fetchSampleTextbookList();
    },[])

    return(
        <div className="containerHome">
            <div className="boxHome-1">
                <div className="gradeTitle">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>그리며 공부하기 샘플</h4>
                </div>
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="improveAbilityCards">
                            {sampleTextbookList && sampleTextbookList.map( subject => {
                                return(
                                    <div key={subject.id} className="improveAbilityCard">
                                        <h5>{subject.sampleSubjectTitle}</h5>
                                        <p>{subject.sampleSubjectDescription}</p>
                                        <Link 
                                            to={`/mathsubject/sample/${subject.urlCode}` }
                                            state={{
                                                "subjectCode": subject.subjectCode,
                                                "problemCode": subject.firstProblemCode,
                                                "subjectTitle": subject.sampleSubjectTitle
                                            }}
                                        >
                                            바로가기
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxHome-2">
                {textbookList && textbookList.map(grade => {
                    return(
                        <div key={grade.phaseCode}>
                            <div className="gradeTitle">
                                <h4 style={{color:"sienna", fontWeight:"bold"}}>{grade.phaseTitle}</h4>
                            </div>
                            <div className="containerList">
                                <div className="containerListItem">
                                    <div className="improveAbilityCards">
                                        {grade.subjects && grade.subjects.map( subject => {
                                            return(
                                                <div key={subject.subjectCode} className="improveAbilityCard">
                                                    <h5>{subject.subjectTitle}</h5>
                                                    <p>{subject.subjectDescription}</p>
                                                    <Link 
                                                        to={`/mathsubject/${randomString}` }
                                                        state={{
                                                            "subjectCode": subject.subjectCode,
                                                            "problemCode": subject.firstProblemCode,
                                                            "subjectTitle": subject.subjectTitle
                                                        }}
                                                    >
                                                        바로가기
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    )
}

export default GradeTotalListTextbook;