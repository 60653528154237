import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
// import axiosapi from "../api/axiosapi";
import useAuth from "../hooks/useAuth";
import './mathsubject.css';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ForStudentMenu from "../components/ForStudentMenu";
// import axiosPrivate from "../api/axiosPrivate";

const StudentConfirm = () => {
	const {auth} = useAuth();
	const [teachers, setTeachers] = useState([]);
	const [selectedTeacher, setSelectedTeacher] = useState(null);
	const [expiredTeacher, setExpiredTeacher] = useState(null);
	const axiosPrivate = useAxiosPrivate();
	const navigateto=useNavigate();
	const location = useLocation();

    useEffect(()=>{
		let isMounted = true;
        const controller = new AbortController();

        const fetchConfirmRequirement = async () => {
            try{
                const response = await axiosPrivate.get(`/teacher/student/viewrequest`, {
					signal: controller.signal
				});
                isMounted && setTeachers(response.data);
            } catch (err) {
				err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

		fetchConfirmRequirement();
		return () => {
            isMounted = false;
            controller.abort();
        }
    },[])

	const confirmSelectTeacher = (teacher) => {
		let isMounted = true;
		const controller = new AbortController();
		// 학생이 등록 확인
		axiosPrivate.post('/teacher/student/confirmrequest', {
			teacherID: teacher.teacherID},
			{
				signal: controller.signal
			}
		).then(response => {
			alert(response.data.message);
		}).catch(err => {
			err?.response?.status === 500 && alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
		});
		return () => {
            isMounted = false;
            controller.abort();
        }
	};

	const expireSelectTeacher = (teacher) => {
		let isMounted = true;
		const controller = new AbortController();
		// 학생이 등록 취소
		axiosPrivate.post('/teacher/student/expirebystudent', {
			teacherID: teacher.teacherID},
			{
				signal: controller.signal
			}
		).then(response => {
			alert(response.data.message);
		}).catch(err => {
			err?.response?.status === 500 && alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
		});
		return () => {
            isMounted = false;
            controller.abort();
        }
	};

	return (
		<div className="mathsubject">
			<div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForStudentMenu />
				</div>
			</div>
			<div className="subjectbox-2" style={{marginTop:"14px"}}>
				<h4 style={{ marginBottom:"10px" }}>선생님 등록 확인 목록</h4>
				<ul>
					{teachers && teachers.length ? (
						<table style={{minWidth:"700px", maxWidth:"900px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"35px" }}>
								<tr>
									<th style={{minWidth:"170px", maxWidth:"420px"}}>이름</th>
									<th style={{minWidth:"170px", maxWidth:"420px"}}>아이디</th>
									<th style={{minWidth:"170px", maxWidth:"420px"}}>상태</th>
									<th style={{ width: '180px', textAlign: 'center' }}></th>
								</tr>
							</thead>
							<tbody>
								{teachers.map((teacher, index) => (
									<tr key={teacher.id} style={{ height:"33px", backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey' }}>
										<td>{teacher.teacherName}</td>
										<td>{teacher.teacherID}</td>
										<td>{teacher.studentConfirm ? "확인 완료" : "확인 대기 중"}</td>
										<td>
											{teacher.studentConfirm === false && <button style={{ backgroundColor: 'lime', borderRadius:'5px', marginRight:'7px' }} onClick={() => setSelectedTeacher(teacher)}>등록 확인</button>}
											<button style={{ backgroundColor: 'lime', borderRadius:'5px' }} onClick={() => setExpiredTeacher(teacher)}>등록 취소</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : ("등록한 선생님이 없습니다.")
					}
				</ul>
				{selectedTeacher && (
					<div style={{ marginTop:"30px"}}>
						<h5 style={{ marginBottom:"10px"}}>등록 확인하시겠습니까?</h5>
						<table style={{ minWidth:"340px", maxWidth:"840px", marginLeft:"30px" }}>
							<thead style={{ backgroundColor: 'yellow', height:"35px" }}>
								<tr>
									<th>이름</th>
									<th>아이디</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height:"33px" }}>
									<td>{selectedTeacher.teacherName}</td>
									<td>{selectedTeacher.teacherID}</td>
								</tr>
							</tbody>
						</table>
						<button style={{ backgroundColor: 'lime', borderRadius:'5px', marginLeft:"30px" }} onClick={() => confirmSelectTeacher(selectedTeacher)}>등록 확인</button>
					</div>
				)}
				{expiredTeacher && (
					<div style={{ marginTop:"30px"}}>
						<h5 style={{ marginBottom:"10px"}}>등록 취소하시겠습니까?</h5>
						<table style={{ minWidth:"340px", maxWidth:"840px", marginLeft:"30px" }}>
							<thead style={{ backgroundColor: 'yellow', height:"35px" }}>
								<tr>
									<th>이름</th>
									<th>아이디</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height:"33px" }}>
									<td>{expiredTeacher.teacherName}</td>
									<td>{expiredTeacher.teacherID}</td>
								</tr>
							</tbody>
						</table>
						<button style={{ backgroundColor: 'lime', borderRadius:'5px', marginLeft:"30px" }} onClick={() => expireSelectTeacher(expiredTeacher)}>등록 취소</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default StudentConfirm;