import { React, useEffect, useState } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams } from 'react-router-dom';
import '../App.css';
// import GetProblemInfo from '../components/GetProblemInfo';
import axiosapi from "../api/axiosapi";
// import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
// import UserSolvingStat from '../components/UserSolvingStat';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const DoorProblem = ()=> {
    const {problemURL}=useParams();
    // const navigateto = useNavigate();
    // const {auth} = useAuth();
    const [problemInfo, setProblemInfo] = useState('');

    useEffect (() => {
        const getProblemInfo = async () => {
            try {
                const response = await axiosapi.get('/door/problem/', {
                    params: {
                        url: problemURL
                    },
                    withCredentials: true
                })
                response.data && setProblemInfo(response.data);
            } catch (err) {
                console.log(err);
            }
        }

        getProblemInfo();
    }, [problemURL])

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
      
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
      
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
      
        return <div>{contentArray}</div>;
    };


    return(
        <div className="problem" key={problemURL}>
            <div className="problemContentFit">
                {<div style={{fontSize:24,fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>
                    <div>
                        {problemInfo.problemTitle && splitContent(problemInfo.problemTitle)}
                    </div>
                </div>}
                {<div style={{marginLeft:"5px"}}>
                    {problemInfo.problemDescription  && splitContent(problemInfo.problemDescription)}

                </div>}
                <div style={{marginLeft:"5px"}}>
                    {problemInfo.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : problemInfo.successfulUserNum === 0 
                            ? `${problemInfo.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${problemInfo.triedUsersNum}명이 도전해 ${problemInfo.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(problemInfo.successfulUserNum/problemInfo.triedUsersNum*100).toFixed(0)}%의 사용자와 같은 자리를 차지하세요.`
                    }
                </div>

                {/* <p style={{marginLeft:"5px"}}>{problemInfo.publicationDate}</p> */}
            
                {problemInfo && <GeoGebraP
                    title={problemInfo.problemTitle}
                    idIn={problemInfo.problemCode}
                    customToolBarIn={problemInfo.customToolBar}
                    allowStyleBarIn={problemInfo.allowStyleBar}
                    ggbBase64In={problemInfo.problemContent}
                    problemType={problemInfo.problemType}
                    showToolBarHelpIn={problemInfo.showToolBarHelp}
                    enableLabelDragIn={problemInfo.enableLabelDrag}
                    enableShiftDragZoomIn={problemInfo.enableShiftDragZoom}
                />}
            
                {/* <div className='problemmove'>
                    {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7)
                        ? <button
                                className='button'
                                onClick={
                                    () => auth?.accessToken
                                        ? navigateto(`/todayproblem/my/s/list`, {state:{
                                            "todayProblemList": todayProblemListV,
                                            "problem": problem,
                                            "todayProblem": todayProblem,
                                            "useStat": useStat,
                                            "isFirst": isFirst,
                                            "isLast": isLast,
                                            "prevProblem": prevProblem,
                                            "nextProblem": nextProblem,
                                            "proID": proID
                                        }})
                                        : navigateto("/login", {state:{"from": location}}, {replace: true})
                                }
                            >
                                내 풀이보기
                            </button>
                        : null
                    }

                    <button
                        className='button'
                        onClick={
                            () => auth?.accessToken
                                ? navigateto(`/todayproblem/image/list/`, {state:{
                                    "todayProblemList": todayProblemListV,
                                    "todayProblem": todayProblem,
                                    "problem":problem
                                }})
                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                        }
                    >
                        그림보기
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default DoorProblem;
