import React from "react";
import { useNavigate } from 'react-router-dom';
import './signup.css';
import '../App.css';

const PaymentFirst = () => {
       const navigateto = useNavigate();

       return (
              <div className="agreementContainer">
                     <div className="agreementBox">
                            <h2>유료서비스 이용권 구매하기</h2>
                            <br></br>
                            <p>이용권을 구매하여 유료회원이 되시면 세계에서 가장 많은 온라인 작도 문제를 풀어볼 수 있는 사이트들 중 하나와 온전히 만나게 됩니다.</p>
                            <p>1개월(11,000원), 2개월(22,000원), 3개월(32,000원) 이용권을 구매할 수 있습니다. 이용권을 구매하면 자동으로 유료회원으로 전환됩니다.</p>
                            <button 
                                   className='button'
                                   onClick={() => navigateto("/payment/2", {state: {"product_code": "regul1mon"}})}
                            >
                                   1개월 이용권 구매하기
                            </button>
                            <br></br>
                            <button 
                                   className='button'
                                   onClick={() => navigateto("/payment/2", {state: {"product_code": "regul2mon"}})}
                            >
                                   2개월 이용권 구매하기
                            </button>
                            <br></br>
                            <button 
                                   className='button'
                                   onClick={() => navigateto("/payment/2", {state: {"product_code": "regul3mon"}})}
                            >
                                   3개월 이용권 구매하기
                            </button>
                            <br></br>
                            <button 
                                   className='button'
                                   onClick={() => navigateto(-1, {replace: true})}
                            >
                                   이전화면으로 돌아가기
                            </button>
                     </div>
              </div>
       )
}

export default PaymentFirst;