import React, {useState} from "react";
import './login.css';
import '../App.css';
import axiosapi from "../api/axiosapi";
import { useNavigate } from "react-router-dom";

const ForgotIdFirst = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const navigateto = useNavigate();
    const [errMsg, setErrMsg] = useState();
    const [checkData, setCheckData] = useState('');

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosapi.get('/user/find/username/email', {params: {
                email: email,
                name: name
            }})
            
            if(response.data === null) {
                alert("회원 정보가 없습니다. 등록한 이름과 이메일을 입력하세요.")
            } else {
                response.data && navigateto(`/forgotid/emailsecond`, {state:{
                        name: name,
                        email: email,
                        username: response.data.username,
                        usernameLength: response.data.username.length
                    }})                
            }
        } catch (err) {
            setErrMsg(err);
            // setCheckData('');
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing name or Password');
            //     alert("Missing name or Password");
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            //     alert("Missing name or Password");
            // } else {
            //     setErrMsg('Login Failed');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // }
        } 
    }

    const handleMobileSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosapi.get('/user/find/username/mobile', {params: {
                mobile: mobile,
                name: name
            }})
            if(response.data === null) {
                alert("회원 정보가 없습니다. 등록한 이름과 휴대폰 또는 전화번호를 입력하세요.")
            } else {
                response.data && navigateto(`/forgotid/mobilesecond`, {state:{
                        name: name,
                        mobile: mobile,
                        username: response.data.username,
                        usernameLength: response.data.username.length
                }})
            }
        } catch (err) {
            setErrMsg(err);
            // setCheckData('');
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing name or Password');
            //     alert("Missing name or Password");
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            //     alert("Missing name or Password");
            // } else {
            //     setErrMsg('Login Failed');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // }
        } 
    }


    return(
        <div className="forgotIdContainer">
            <div className="forgotIdBox-1">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="resgisteredemail-tab" data-bs-toggle="tab" data-bs-target="#resgisteredemail" type="button" role="tab" aria-controls="resgisteredemail" aria-selected="true">이메일로 찾기</button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">휴대폰으로 찾기</button>
                    </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="resgisteredemail" role="tabpanel" aria-labelledby="home-tab">
                        <div>
                            <form onSubmit={handleEmailSubmit}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
                                        <label htmlFor="name">이름:</label>
                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="email">등록한 이메일 주소:</label>
                                        <input type="email" id="email" value={email} placeholder="registered email address" onChange={e => setEmail(e.target.value)} required />
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"120px", padding:"7px 15px"}}>다음 단계</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="profile-tab">
                        <div>
                            <form onSubmit={handleMobileSubmit}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
                                        <label htmlFor="name">이름:</label>
                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="email">등록한 휴대폰 번호:</label>
                                        <input type="phone" id="mobile" value={mobile} placeholder="registered mobile number" onChange={e => setMobile(e.target.value)} required />
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"120px", padding:"7px 15px"}}>다음 단계</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ForgotIdFirst;