import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import '../App.css';

const MyInfo = () => {
       const axiosPrivate = useAxiosPrivate();
       const navigateto = useNavigate();
       const [myInfo, setMyInfo] = useState({
              name: '',
              username: '',
              email: '',
              mobile: '',
              zipcode: '',
              address: '',
              detailAddress: '',
              userType: '',
              isTeacher: '',
              premiumExpireDate: '',
              birthYear: '',
       });

       useEffect(() => {
              const controller = new AbortController();

              const fetchMyInfo = async () => {
                     try {
                            const response = await axiosPrivate.get('/user/my/info', {signal: controller.signal});
                            response.data && setMyInfo(prevState => ({
                                   ...prevState,
                                   name: response.data.name,
                                   username: response.data.username,
                                   email: response.data.email,
                                   mobile: response.data.mobile,
                                   zipcode: response.data.zipcode,
                                   address: response.data.address,
                                   detailAddress: response.data.detailAddress,
                                   userType: response.data.userType,
                                   isTeacher: response.data.isTeacher,
                                   premiumExpireDate: response.data.premiumExpireDate,
                                   birthYear: response.data.birthYear
                            }));
                     } catch (e) {
                            console.log(e);
                     }
              }

              fetchMyInfo();

              return () => controller.abort();
       },[]);

       return(
              <div>
                     <div style={styles.container}>
                            <h4>{myInfo.name}님의 회원정보</h4>
                            <div style={styles.infoContainer}>
                                   <div style={styles.infoRow}>
                                          <span style={styles.label}>이메일</span>
                                          <span style={styles.value}>{myInfo.email}</span>
                                   </div>
                                   <div style={styles.infoRow}>
                                          <span style={styles.label}>전화번호</span>
                                          <span style={styles.value}>{myInfo.mobile}</span>
                                   </div>
                                   <div style={styles.infoRow}>
                                          <span style={styles.label}>주소</span>
                                          <div style={styles.value}>
                                                 <span>({myInfo.zipcode})</span>
                                                 <span> {myInfo.address}</span>
                                                 <span> {myInfo.detailAddress}</span>
                                          </div>
                                   </div>
                                   <div style={styles.infoRow}>
                                          <span style={styles.label}>출생연도</span>
                                          <span style={styles.value}>{myInfo.birthYear}년</span>
                                   </div>
                                   <div style={styles.infoRow}>
                                          <span style={styles.label}>일반/유료회원</span>
                                          <span style={styles.value}>{myInfo.userType}</span>
                                   </div>
                                   {myInfo.userType === "유료회원" 
                                          ? <div style={styles.infoRow}>
                                                 <span style={styles.label}>유료정보이용 만료일</span>
                                                 <span style={styles.value}>{myInfo.premiumExpireDate}</span>
                                          </div>
                                          : <span></span>
                                   }
                            </div>
                            <div>
                                   <button 
                                          className='button'
                                          onClick={() => myInfo.isTeacher ? navigateto("/teacher/myinfo/update/") : navigateto("/student/myinfo/update/")}
                                   >
                                          회원정보 수정
                                   </button>
                                   <button 
                                          className='button'
                                          onClick={() => navigateto("/payment/1")}
                                   >
                                          유료사용권 구매
                                   </button>
                            </div>
                     </div>
              </div>
       );

}

const styles = {
       container: {
              textAlign: 'center',
              margin: '50px auto',
              padding: '20px',
              maxWidth: '900px',
              border: '1px solid #ccc',
              borderRadius: '10px',
              backgroundColor: '#f9f9f9',
       },
       infoContainer: {
              textAlign: 'left',
              marginTop: '20px',
       },
       infoRow: {
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '5px',
       },
       label: {
              fontWeight: 'bold',
              marginRight: '10px',
       },
       value: {
              textAlign: 'right',
              flex: 1,
       },
};

export default MyInfo;