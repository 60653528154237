import React, { useState, useEffect } from 'react';
import GeoGebraMySolAnimation from '../components/GeoGebraMySolAnimation';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../App.css';

const TodayProblemMySolAnimation = ()=> {
    const {proIDMy}=useParams();
    const navigateto = useNavigate();
    const {todayProblemList, todayProblem, useStat, myAnswerList, myConstructionStep, problem, myAnswerRaw, myPointConstruction, isFirst, isLast, prevProblem, nextProblem, proID} = useLocation().state;
    const [randomString1, setRandomString1] = useState();
    const [randomString2, setRandomString2] = useState();
    const [randomString3, setRandomString3] = useState();
    const [randomString4, setRandomString4] = useState();
    const [randomString5, setRandomString5] = useState();

    useEffect(() => {
        const revivalData = () => {
            const letters = '1234567890';
            let randomString1l = '';
            let randomString2l = '';
            let randomString3l = '';
            let randomString4l = '';
            let randomString5l = '';
            for (let i = 0; i < 3; i++) {
                randomString1l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString1(randomString1l); 
            for (let i = 0; i < 8; i++) {
                randomString2l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString2(randomString2l);
            for (let i = 0; i < 5; i++) {
                randomString3l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString3(randomString3l);
            for (let i = 0; i < 7; i++) {
                randomString4l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString4(randomString4l);
            for (let i = 0; i < 6; i++) {
                randomString5l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString5(randomString5l);
        };
        revivalData();
    },[proIDMy])

    return(
        <div className="problem" key={myAnswerRaw.answerMark}>
            <div className="problemContentFit">
                <h4>{myAnswerRaw.solvingTime.substring(6,10)}년 {myAnswerRaw.solvingTime.substring(3,5)}월 {myAnswerRaw.solvingTime.substring(0,2)}일 {myAnswerRaw.solvingTime.substring(12,17)}의 풀이</h4>
                <GeoGebraMySolAnimation
                    idIn={problem.problemCode}
                    customToolBarIn={problem.customToolBar}
                    allowStyleBarIn={problem.allowStyleBar}
                    ggbBase64In={problem.problemContent}
                    myPointAnswer={myPointConstruction}
                    myAnswerStep={myConstructionStep}
                    problemType={problem.problemType}
                    startAnimation={myAnswerRaw.startAnimation}
                />
                <div className='problemmove'>
                    {isFirst 
                        ? null 
                        : <button 
                                className='button'
                                onClick={() => navigateto(`/todayproblem/${randomString1}${prevProblem.id.toString().padStart(10,"0").slice(8,10)}${randomString2}${prevProblem.id.toString().padStart(10,"0").slice(7,8)}${randomString3}${prevProblem.id.toString().padStart(10,"0").slice(6,7)}${randomString4}${prevProblem.id.toString().padStart(10,"0").slice(4,6)}${randomString5}${prevProblem.id.toString().padStart(10,"0").slice(0,4)}`, {state:{
                                    "todayProblemList": todayProblemList,
                                    "todayProblem": prevProblem
                                }})}
                            >
                                이전 문제
                            </button>
                    } 
                    {isLast 
                        ? null 
                        : <button 
                                className='button'
                                onClick={() => navigateto(`/todayproblem/${randomString1}${nextProblem.id.toString().padStart(10,"0").slice(8,10)}${randomString2}${nextProblem.id.toString().padStart(10,"0").slice(7,8)}${randomString3}${nextProblem.id.toString().padStart(10,"0").slice(6,7)}${randomString4}${nextProblem.id.toString().padStart(10,"0").slice(4,6)}${randomString5}${nextProblem.id.toString().padStart(10,"0").slice(0,4)}`, {state:{
                                    "todayProblemList": todayProblemList, 
                                    "todayProblem": nextProblem
                                }})}
                            >
                                다음 문제
                            </button>
                    }
                    <button 
                        className='button'
                        onClick={() => navigateto(`/todayproblem/${randomString1}${problem.id.toString().padStart(10,"0").slice(8,10)}${randomString2}${problem.id.toString().padStart(10,"0").slice(7,8)}${randomString3}${problem.id.toString().padStart(10,"0").slice(6,7)}${randomString4}${problem.id.toString().padStart(10,"0").slice(4,6)}${randomString5}${problem.id.toString().padStart(10,"0").slice(0,4)}`, {state:{
                            "todayProblemList": todayProblemList,
                            "todayProblem": todayProblem
                        }})}
                    >
                        문제로 돌아가기
                    </button>
                    <button 
                        className='button'
                        onClick={() => navigateto(`/todayproblem/my/${proIDMy}`, {state:{
                            "todayProblemList": todayProblemList,
                            "problem": problem,
                            "todayProblem": todayProblem,
                            "useStat": useStat,
                            "myAnswerList": myAnswerList,
                            "isFirst": isFirst,
                            "isLast": isLast,
                            "prevProblem": prevProblem,
                            "nextProblem": nextProblem,
                            "proID": proID
                        }})}
                    >
                        풀이 최종 화면
                    </button>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default TodayProblemMySolAnimation;