import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div style={{marginLeft:'30px'}}>
            <p style={{fontWeight:'bolder', marginBottom:'10px'}}>(주)미래를여는생각</p>
            <div style={styles.container}>
                <div style={styles.item}>
                    <p style={{marginBottom:'1px'}}>주소  서울특별시 서초구 서초중앙로18길 31, 1층 14호</p>
                    <p style={{marginBottom:'1px'}}>전화번호  070-8654-7225</p>
                    <p style={{marginBottom:'1px'}}>대표자  최재헌</p>
                    <p style={{marginBottom:'1px'}}>사업자등록번호  247-86-01602</p>
                    <p>통신판매업 신고번호  제2019-서울서초-0453호</p>
                </div>
                <div style={styles.item}>
                    <p style={{marginBottom:'1px'}}><Link to={`/policy/privacypolicy`}>개인정보 보호정책</Link></p>
                    <p style={{marginBottom:'1px'}}><Link to={`/policy/freemembershipagreement`}>이용약관</Link></p>
                    <p style={{marginBottom:'1px'}}><Link to={`/policy/copyright`}>저작권 보호규정</Link></p>
                    <p style={{marginBottom:'1px'}}><Link to={`/policy/premiumserviceagreement`}>유료서비스 이용약관</Link></p>
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // 2개의 열로 분할
        gap: "10px", // 열 사이에 간격 추가
        // alignItems: "center", // 세로로 가운데 정렬
    },
    item: {
        padding: "10px",
        textAlign: "left",
    },
};

export default Footer;