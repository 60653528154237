import React, {useState, useEffect} from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useLocation, useNavigate, Link } from 'react-router-dom';
// import axiosapi from '../api/axiosapi';
import './mathsubject.css';
import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';
import { VscCheck,VscRocket } from "react-icons/vsc";
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const ExerciseCalculationSample = ()=> {
    const {categoryCode, categoryTitle, problemCode} = useLocation().state;
    // const {auth} = useAuth();
    const navigateto=useNavigate();
    const location = useLocation();
    const [category, setCategory] = useState('');
    const [count, setCount] = useState(0);
    // const [error2, setError2]=useState();
    const {problem, errP} = GetProblemInfo({"problemID": problemCode});
    // const [images, setImages] = useState([]);
    const {useStat} = UserSolvingStat({"problemCode": problemCode});
    const axiosPrivate = useAxiosPrivate();

    useEffect(()=> {
        let isMounted = true;
        const controller = new AbortController();

        const fetchCategoryList = async () => {
            try {
                const response = await axiosPrivate.get('/category/problem/all/sample/', {
                    params: {
                        category_code: categoryCode
                    },
                    signal: controller.signal
                })
                isMounted && response.data && setCategory(response.data);
                isMounted && response.data && setCount(count+1);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // navigateto('/login', { state: { 
                //     from: location, 
                //     categoryCode: categoryCode,
                //     categoryTitle: categoryTitle,
                //     problemCode: problemCode
                // }, replace: true });
                // setError2(e)
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            }
        }

        fetchCategoryList()

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[categoryCode]);

    // useEffect(() => {
    //     const imagesSetting = () => {
    //         setImages([]);
    //         problem.images && problem.images.map(image => {
    //             setImages(prev => [...prev, image]);
    //         })
    //     }

    //     imagesSetting();
    // },[problem])

    function ProblemLinkC ({LinkTitle, LinkId}) {
        return(
            <div className="subjectsidebar-4">
                {LinkId === problemCode
                    ? <b style ={{color:'red'}}>{LinkTitle} <VscCheck/> <VscRocket/></b>
                    : <Link to={`/calculationexercise/sample/`} state={{
                                "categoryCode": categoryCode,
                                "categoryTitle": categoryTitle,
                                "problemCode": LinkId
                            }}>
                                {LinkTitle}
                        </Link>
                }
            </div>
        )
    }

    function ProblemLink ({LinkTitle, LinkId}) {
        return(
            <div className="subjectsidebar-4">
                <Link to={`/calculationexercise/sample/`} state={{
                        "categoryCode": categoryCode,
                        "categoryTitle": categoryTitle,
                        "problemCode": LinkId
                    }}>
                        {LinkTitle}
                </Link>
            </div>
        )
    }

    if (errP) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="mathsubject" key={problem.problemCode}>
            <div className="subjectbox-1">
                <h4 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px", marginLeft:"5px"}}>
                    {categoryTitle}
                </h4>
                {
                    category && category.map(problem => {
                        return(
                            <div key={problem.problemCode}>
                                {problem.problemCode === problemCode
                                    ? <ProblemLinkC LinkTitle={problem.problemTitle} LinkId={problem.problemCode} /> 
                                    : <ProblemLink LinkTitle={problem.problemTitle} LinkId={problem.problemCode} />
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="subjectbox-2">
                <div className="problem">
                    <div className="problemContentFit">
                        <ProblemStatDescription 
                            topCategoryTitle={categoryTitle}
                            problemTitle={problem.problemTitle} 
                            problemDescription={problem.problemDescription}
                            problemId={problem.idIn}
                            useStat={useStat}
                        />
                        {problem && <GeoGebraP
                            title={problem.problemTitle}
                            idIn={problem.problemCode}
                            customToolBarIn={problem.customToolBar}
                            allowStyleBarIn={problem.allowStyleBar}
                            ggbBase64In={problem.problemContent}
                            problemType={problem.problemType}
                            showToolBarHelpIn={problem.showToolBarHelp}
                            enableLabelDragIn={problem.enableLabelDrag}
                            enableShiftDragZoomIn={problem.enableShiftDragZoom}
                        />}
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default ExerciseCalculationSample;