import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import '../pages/signup.css';
import '../App.css';

const PurchaseHistory = () => {
       const axiosPrivate = useAxiosPrivate();
       const [purchases, setPurchases] = useState([]);
       const [loading, setLoading] = useState(true);
       const [error, setError] = useState(null);

       useEffect (() => {
              const fetchPurchaseHistory = async () => {
                     try {
                            const response = await axiosPrivate.get('/pay/paymenthistory', {withCredentials: true});
                            response.data && setPurchases(response.data);
                     } catch (err) {
                            setError(err.message);
                            console.log(err);
                     } finally {
                            setLoading(false);
                     }
              }

              fetchPurchaseHistory();
       }, []);

       if (loading) return <p>Loading...</p>;
       if (error) return <p>Error: {error}</p>;
       return (
              <div>
                     <h2>결제이력</h2>
                     {purchases.length > 0 ? (
                            <table style={styles.table}>
                                   <thead>
                                          <tr>
                                                 <th style={styles.th}>주문번호</th>
                                                 <th style={styles.th}>이용권명</th>
                                                 <th style={styles.th}>결제금액</th>
                                                 <th style={styles.th}>결제일</th>
                                                 <th style={styles.th}>결제 이전 만료일</th>
                                          </tr>
                                   </thead>
                                   <tbody>
                                          {purchases.map((purchase, index) => (
                                                 <tr
                                                        key={purchase.pay_code}
                                                        style={index % 2 === 0 ? styles.trEven : null}
                                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.trHover.backgroundColor)}
                                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.trEven.backgroundColor : 'transparent')}
                                                 >      <td style={styles.td}>{purchase.pay_code}</td>
                                                        <td style={styles.td}>{purchase.product_name}</td>
                                                        <td style={{ ...styles.td, ...styles.tdRightAligned }}>{purchase.price}원</td>
                                                        <td style={styles.td}>{purchase.pay_date.split('T')[0]}</td>
                                                        <td style={styles.td}>{purchase.previous_expire_date.split('T')[0]}</td>
                                                 </tr>
                                          ))}
                                   </tbody>
                            </table>
                     ) : (
                            <p>결제이력이 없습니다.</p>
                     )}
              </div>
       )       
}

const styles = {
       table: {
              width: '100%',
              borderCollapse: 'collapse',
              marginTop: '20px',
              fontFamily: 'Arial, sans-serif',
       },
       th: {
              backgroundColor: '#f2f2f2',
              color: '#333',
              border: '1px solid #ddd',
              padding: '12px',
              textAlign: 'left',
       },
       td: {
              border: '1px solid #ddd',
              padding: '12px',
              textAlign: 'left',
              color: '#555',
       },
       trEven: {
              backgroundColor: '#f9f9f9',
       },
       trHover: {
              backgroundColor: '#f1f1f1',
       },
       tdRightAligned: {
              textAlign: 'right',
              fontWeight: 'bold',
              color: '#e60023',
       }
};

export default PurchaseHistory;