import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./home.css"
import axiosapi from "../api/axiosapi";
import Footer from "../components/Footer";

const GeometricColoringList = () => {
    const navigateto = useNavigate();
    const [geometricColoringList, setGeometricColoringList] = useState([]);
    const [sampleGeometricColoringList, setSampleGeometricColoringList] = useState([]);
    // const [randomString, setRandomString] = useState();
    const [err1, setErr1] = useState(false);
    const [err2, setErr2] = useState(false);

    useEffect(() => {
        const fetchGeometricColoringList = async () => {
            try {
                const response = await axiosapi.get('/canvascategory/all/');
                setGeometricColoringList(response.data);
            } catch (err) {
                setErr1(true);
                // console.log("err1: ", err);
            }
        }

        const fetchSampleGeometricColoringList = async () => {
            try {
                const response2 = await axiosapi.get('/sample/category/geometriccoloring/');
                setSampleGeometricColoringList(response2.data);
            } catch (err) {
                setErr2(true);
                // console.log("err2: ", err);
            }
        }

        fetchGeometricColoringList();
        fetchSampleGeometricColoringList();
    }, [])

    // useEffect(() => {
    //     const randomAlphabetString = () => {
    //         const letters = '1234567890';
    //         let randomStringl = '';
    //         for (let i = 0; i < 12; i++) {
    //             randomStringl += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString(randomStringl); 
    //       };
    //       randomAlphabetString();
    // },[])

    if (err1) {
        return (
            <div className="problem">
                <p>"색칠하며 도형 익히기 목록을 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="containerHome">
            {!err2 && 
                <div className="boxHome-1">
                    <div className="gradeTitle">
                        <h4 style={{color:"sienna", fontWeight:"bold"}}>색칠하기 샘플</h4>
                    </div>
                    <div className="containerList">
                        <div className="containerListItem">
                            <div className="improveAbilityCards">
                                {sampleGeometricColoringList && sampleGeometricColoringList.map( sampleGeoColor => {
                                    return(
                                        <div key={sampleGeoColor.id} className="improveAbilityCard">
                                            <h5>{sampleGeoColor.canvasCategoryTitle}</h5>
                                            {/* <p>{sampleGeoColor.canvasCategoryDescription}</p> */}
                                            <Link 
                                                to={`/geometriccoloring/sample/1` }
                                                state={{
                                                    "canvasCategoryCode": sampleGeoColor.canvasCategoryCode,
                                                    "canvasCode": sampleGeoColor.firstCanvasCode,
                                                    "canvasCategoryTitle": sampleGeoColor.canvasCategoryTitle
                                                }}
                                            >
                                                색칠하기
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="boxHome-2">
                <div className="gradeTitle">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>색칠하기</h4>
                </div>
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="improveAbilityCards">
                            {geometricColoringList.map(geoColor => {
                                return (
                                    <div key={geoColor.id} className="improveAbilityCard">
                                        <h4>{geoColor.canvasCategoryTitle}</h4>
                                        {/* <p>{geoColor.canvasCategoryDescription}</p> */}
                                        <Link 
                                            to={`/geometriccoloring/1`}
                                            state={{
                                                "canvasCategoryCode": geoColor.canvasCategoryCode,
                                                "canvasCategoryTitle": geoColor.canvasCategoryTitle,
                                                "canvasCode":geoColor.firstCanvasCode
                                            }}
                                        >
                                            색칠하기
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    );
}

export default GeometricColoringList;