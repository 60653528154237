import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import '../App.css';

const TodayProblemImageList = () => {
    const {todayProblem, todayProblemList, problem} = useLocation().state;
    const navigateto = useNavigate();

    if (problem.images.length === 0) {
        return (
            <div className="problem">
                <p>"{todayProblem.todayProblemTitle}" 기반의 이미지가 없습니다. 문제를 풀어보고, 풀던 문제를 이용해 생각을 그림으로 표현해 보세요.</p>
                <button 
                    className='button'
                    onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                        "todayProblemList": todayProblemList,
                        "todayProblem": todayProblem
                    }})}
                >
                    문제로 돌아가기
                </button>
            </div>
        )
    }

    return (
        <div className="problem">
            <h5>"{todayProblem.todayProblemTitle}" 기반의 그림 리스트</h5>
            <br/>
            {problem.images.map(image => {
                return(
                    <div key={image.id}>
                        <Link 
                            to={`/viewimage/${image.imageFile}/${image.backgroundColor}`}
                            target="_blank"
                        >
                            <p style={{marginLeft:'10px'}}>{image.coloringTitle}</p>
                        </Link>
                    </div>
                )
            })}

            <button 
                className='button'
                onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                    "todayProblemList": todayProblemList,
                    "todayProblem": todayProblem
                }})}
            >
                문제로 돌아가기
            </button>
        </div>
    )
}

export default TodayProblemImageList;