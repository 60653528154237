import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import '../App.css';

const MathSubjectImageList = () => {
    const {subjectCode, problemCode, subjectTitle, firstProbID, problemTitle, images, isSample} = useLocation().state;
    const navigateto = useNavigate();

    if (images.length === 0) {
        return (
            <div className="problem">
                <p>"{problemTitle}" 기반의 이미지가 없습니다.</p>
                <button 
                    className='button'
                    onClick={() => isSample 
                        ? navigateto(`/mathsubject/sample/${firstProbID}`, {state:{
                            "subjectCode": subjectCode,
                            "problemCode": problemCode,
                            "subjectTitle": subjectTitle
                        }})
                        : navigateto(`/mathsubject/${firstProbID}`, {state:{
                            "subjectCode": subjectCode,
                            "problemCode": problemCode,
                            "subjectTitle": subjectTitle
                        }})
                    }
                >
                    문제로 돌아가기
                </button>
            </div>
        )
    }

    return (
        <div className="problem">
            <h5>"{problemTitle}" 기반의 이미지</h5>
            <br/>
            {images.map(image => {
                return(
                    <div key={image.id}>
                        <Link 
                            to={`/viewimage/${image.imageFile}/${image.backgroundColor}`}
                            target="_blank"
                        >
                            <p style={{marginLeft:'10px'}}>{image.coloringTitle}</p>
                        </Link>
                    </div>
                )
            })}

            <button 
                className='button'
                onClick={() => isSample 
                    ? navigateto(`/mathsubject/sample/${firstProbID}`, {state:{
                        "subjectCode": subjectCode,
                        "problemCode": problemCode,
                        "subjectTitle": subjectTitle
                    }})
                    : navigateto(`/mathsubject/${firstProbID}`, {state:{
                        "subjectCode": subjectCode,
                        "problemCode": problemCode,
                        "subjectTitle": subjectTitle
                    }})
                }
            >
                문제로 돌아가기
            </button>
        </div>
    )
}

export default MathSubjectImageList;