import { useState, useEffect } from "react";
// import axiosapi from "../api/axiosapi";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ViewMyAnswerItem = ({answerMark}) => {
    // const {auth} = useAuth();
    const [myAnswerRaw, setMyAnswerRaw] = useState('');
    const [myConstructionStep, setMyConstructionStep] = useState([]);
    const [myPointConstruction, setMyPointComstruction] = useState([]);
    // const [count, setCount] = useState(0);
    const [errAns, setErrAns] = useState(false);
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        setErrAns(false);

        const fetchMyAnswers = async () => {
            try {
                const response = await axiosPrivate.get('/solve/myanswer/', {
                    params: {
                        answerMark: answerMark
                    },
                    signal: controller.signal
                });
                isMounted && response.data && setMyAnswerRaw(response.data);

                const myConstructStep = isMounted && response.data && response.data.solvingContent.split("|");
                setMyConstructionStep(myConstructStep);

                const myPointConstruct = isMounted && response.data && response.data.solvingPoint.split("|");
                setMyPointComstruction(myPointConstruct);

            } catch (err) {
                err?.response?.status === 500 && setErrAns(true);
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            } finally {
                setLoading(false);
            }
        }
        
        fetchMyAnswers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [answerMark])

    return {myAnswerRaw, myConstructionStep, myPointConstruction, errAns, loading}
}

export default ViewMyAnswerItem;