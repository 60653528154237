import React from "react";
import './mathsubject.css';
import MyInfo from "../components/MyInfo";
import ForStudentMenu from "../components/ForStudentMenu";

const StudentMyInfo = () => {
       return (
              <div className="mathsubject">
                     <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForStudentMenu />
				</div>
			</div>
                     <div className="subjectbox-2" style={{marginTop:"14px"}}>
                            <MyInfo />
                     </div>
              </div>
       )
}

export default StudentMyInfo;