import React from 'react';
import ForStudentMenu from '../components/ForStudentMenu';
import './mathsubject.css';
import '../App.css';

const StudentFreeClass = () => {
       const handleClick = () => {
              const url = 'https://zoom.us/j/94264861721?pwd=Lyt2QUJKL2lHcnJSWHcrVlJNSXpZdz09'; // 새 창에서 열 URL
              window.open(url, '_blank', 'noopener,noreferrer');

              // const fullUrl = `${url}?${params.toString()}`; // URL에 파라미터 추가
              // window.open(fullUrl, '_blank', 'noopener,noreferrer');
       }

       return (
              <div className="mathsubject">
                     <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForStudentMenu />
				</div>
			</div>
                     <div className="subjectbox-2" style={{marginTop:"14px"}}>
                            <h2>티프매쓰 유료회원을 위한 무료 화상교육 안내</h2>
                            <br></br>
                            <p>티프매쓰의 유료서비스를 이용하시는 회원님들을 위하여 비대면 무료 화상 교육을 진행합니다.</p>
                            <br></br>
                            <p>도형 등 구체물을 이용한 체험 중심의 수학 교과 학습을 통해 개념원리에 대한 이해와 생각하는 힘을 키워줄 것입니다.</p>
                            <br></br>
                            <p>일시 : 월 수 오후 5시 00분 ~ 5시 40분 (40분; 휴일, 공휴일 제외)</p>
                            <p>       월요일은 5학년 과정을 진행하고, 수요일은 3학년 과정을 진행합니다.</p>
                            <p>준비 사항 : 월 11,000원의 유료서비스를 가입하고, 티프매쓰에 로그인한 후 줌의 교실에 참여합니다.</p>
                            <button onClick={handleClick} className="button" style={{ marginTop: '50px' }}>
                                   줌 화상교실 참여하기
                            </button>
                     </div>
              </div>
       )
}

export default StudentFreeClass;