import React from 'react';
import GeoGebraMySolAnimation from '../components/GeoGebraMySolAnimation';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import './mathsubject.css';
import ForTeacherMenu from '../components/ForTeacherMenu';

const StudentAnswerAnimation = ()=> {
    const {studentAns}=useParams();
    const navigateto = useNavigate();
    const {student, studentAnswer, studentAnswers, myConstructionStep, myAnswerRaw, myPointConstruction} = useLocation().state;
    
    return(
        <div className="mathsubject">
            <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForTeacherMenu />
				</div>
			</div>
            <div className="subjectbox-2" style={{marginTop:"14px"}}>
                <div className="problem" key={myAnswerRaw.answerMark}>
                    <div className="problemContentFit">
                        <h4>{myAnswerRaw.problemTitle} | {myAnswerRaw.solvingTime.substring(6,10)}년 {myAnswerRaw.solvingTime.substring(3,5)}월 {myAnswerRaw.solvingTime.substring(0,2)}일 {myAnswerRaw.solvingTime.substring(12,17)}의 풀이</h4>
                        <GeoGebraMySolAnimation
                            idIn={myAnswerRaw.problemCode}
                            customToolBarIn={myAnswerRaw.customToolBar}
                            allowStyleBarIn={myAnswerRaw.allowStyleBar}
                            ggbBase64In={myAnswerRaw.problemContent}
                            myPointAnswer={myPointConstruction}
                            myAnswerStep={myConstructionStep}
                            problemType={myAnswerRaw.problemType}
                            startAnimation={myAnswerRaw.startAnimation}
                        />
                        <div className='problemmove'>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/student/studentanswerlist`, {state:{
                                    "student": student
                                }})}
                            >
                                문제 풀이 목록
                            </button>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/student/studentanswer/${studentAns}`, {state:{
                                    "student": student,
                                    "studentAnswer": studentAnswer,
                                    "studentAnswers": studentAnswers
                                }})}
                            >
                                풀이 최종 화면
                            </button>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default StudentAnswerAnimation;