import React, { useState, useEffect } from "react";
import "./mathsubject.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import axiosapi from "../api/axiosapi";
// import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ForTeacherMenu from "../components/ForTeacherMenu";
// import axiosPrivate from "../api/axiosPrivate";

const StudentAnswerList = () => {
    // const {auth} = useAuth();
    const {student} = useLocation().state;
    const [studentAnswers, setStudentAnswers] = useState([]);
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigateto=useNavigate();
	const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchStudentAnswerList = async () => {
            try {
                const response = await axiosPrivate.get('/teacher/student/problemsolvinglist', {
                    signal: controller.signal,
                    params: {
                        studentID: student.studentID
                    }
                })
                isMounted && response.data && setStudentAnswers(response.data);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

        fetchStudentAnswerList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[student])


    return(
        <div className="mathsubject">
            <div className="subjectbox-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForTeacherMenu />
				</div>
			</div>
            <div className="subjectbox-2" style={{marginTop:"14px"}}>
                <h4 style={{ marginBottom:"10px" }}>{student.studentName}의 문제풀이 리스트</h4>
                <table style={{minWidth:"700px", maxWidth:"900px"}}>
                    <thead style={{ backgroundColor: 'yellow', height:"35px" }}>
                        <tr>
                            <th>문제</th>
                            <th>풀이 시간</th>
                            <th>풀이 여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentAnswers.map((answer) => {
                            return (
                                <tr key={answer.id} style={{marginBottom:"1rem"}}>
                                    <td>
                                        <Link 
                                            to={`/teacher/student/studentanswer/${answer.answerMark}`}
                                            state={{
                                                "student": student,
                                                "studentAnswer": answer,
                                                "studentAnswers": studentAnswers
                                            }}
                                        >
                                            {answer.problemTitle}
                                        </Link>
                                    </td>
                                    <td style={{width:"160px"}}>{answer.solvingTime}</td>
                                    <td style={{width:"80px"}}>{answer.isSuccess ? "풀이 성공" : "풀이 실패"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="boxListFooter-11">
                Footer
            </div>
        </div>
    );
}

export default StudentAnswerList;