import React from 'react';
import useAuth from '../hooks/useAuth';
import './mathsubject.css';
import '../App.css';
import ForTeacherMenu from '../components/ForTeacherMenu';
import UpdateMyInfo  from '../components/UpdateMyInfo';

const TeacherUpdateMyInfo = () => {
       const {auth} = useAuth();

       if (auth?.userRole1 !== "teacher") {
              return(
                     <div className="problem">
                            <p>티프매쓰에 선생님으로 등록하신 분들만 사용할 수 있습니다. 티프매쓰 선생님으로 등록 신청을 하세요.</p>
                     </div>
              )
       }
       return(
              <div className="mathsubject">
                     <div className="subjectbox-1">
                            <div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                                   <ForTeacherMenu />
                            </div>
                     </div>
                     <div className="subjectbox-2" style={{marginTop:"14px"}}>
                            <UpdateMyInfo />
                     </div>
              </div>
       )
}

export default TeacherUpdateMyInfo;