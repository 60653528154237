import axiosapi from "../api/axiosapi";
import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "./home.css";
import Footer from "../components/Footer";

const GradeList = () => {
    const [grade, setGrade] = useState('');
    // const {gradeID} = useParams();
    const {phaseCode, phaseTitle, phaseDescription} = useLocation().state;
    // const location = useLocation();
    const [randomString, setRandomString] = useState();

    useEffect(() => {
        const fetchGradeList = async () => {
            try {
                const response = await axiosapi.get('/phase/'+phaseCode)
                response.data && setGrade(response.data);
            } catch(err) {
                console.log(err)
            }
            
        }

        fetchGradeList();
    },[phaseCode])

    useEffect(() => {
        const randomAlphabetString = () => {
            const letters = '1234567890';
            let randomStringl = '';
            for (let i = 0; i < 12; i++) {
                randomStringl += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString(randomStringl); 
          };
          randomAlphabetString();
    },[grade])
   
    return(
        <div className="containerHome">
            <div className="boxHome-1">
                <div className="gradeTitle">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>{phaseTitle}</h4>
                </div>
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="improveAbilityCards">
                            {grade && grade.map( grade => {
                                return(
                                    <div key={grade.subjectCode} className="improveAbilityCard">
                                        <h5>{grade.subjectTitle}</h5>
                                        <p>{grade.subjectDescription}</p>
                                        <Link 
                                            to={`/mathsubject/${randomString}` }
                                            state={{
                                                "subjectCode": grade.subjectCode,
                                                "problemCode": grade.firstProblemCode,
                                                "subjectTitle": grade.subjectTitle
                                            }}
                                        >
                                            바로가기
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxHome-11">
                <Footer/>
            </div>
        </div>
    )
}

export default GradeList;