import React, {useState} from "react";
import './login.css';
import '../App.css';
import axiosapi from "../api/axiosapi";
import { useNavigate, Link } from "react-router-dom";

const ForgotPwdFirst = () => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const navigateto = useNavigate();
    const [errMsg, setErrMsg] = useState();
    const [checkData, setCheckData] = useState('');
    const [code, setCode] = useState('');

    const sendTempPasswordEmail = async (username, email) => {
        try {
            const response = await axiosapi.post('/user/find/password/sendbyemail', {username, email})
        } catch (err) {}
    }

    const sendTempPasswordMobile = async (username, mobile) => {
        try {
            const response = await axiosapi.post('/sendPasswordByMobile', {username, mobile})
        } catch (err) {}
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        const requestEmailVerificatinCodeData = {
            email: email,
            name: name,
            username:username
        }

        try {
            const response = await axiosapi.post('/user/find/password/email', requestEmailVerificatinCodeData)

            response.data && alert(`${response.data.message}`)
        } catch (err) {
            alert("연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.");
            // setCheckData('');
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing name or Password');
            //     alert("Missing name or Password");
            // } else {
            //     setErrMsg('Login Failed');
            //     alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            // }
        } 
    }

    const verifyCode = async (e) => {
        e.preventDefault();

        const emailVerificatinCodeData = {
            email: email,
            code: code,
            username:username
        }

        try {
            const response = await axiosapi.post('/user/find/password/email/verification', emailVerificatinCodeData)
            response.data && alert(`${response.data.message}`)
            if (response.data.code === 200) {
                navigateto(`/forgotpassword/second`, {
                    state: { username}, // 상태 전달
                })
            }
        } catch (err) {
            alert("연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.");
        }
    }

    const handleMobileSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosapi.get('/user/find/password/mobile', {params: {
                mobile: mobile,
                name: name,
                username: username
            }})
            if(response.data.length === 0) {
                alert("회원 정보가 없습니다. 등록한 이름과 아이디, 휴대폰 번호를 확인하세요.")
            } else {
                response.data.map(user => {
                    setCheckData(user.data);
                    sendTempPasswordMobile(username, mobile);
                    alert("임시 비밀번호를 문자로 보내드렸습니다. 임시 비밀번호로 로그인한 후 비밀번호를 변경해 주세요.");
                    navigateto(`/login`);
                })
            }
        } catch (err) {
            setCheckData('');
            if (!err?.response) {
                setErrMsg('No Server Response');
                alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            } else if (err.response?.status === 400) {
                setErrMsg('Missing name or Password');
                alert("Missing name or Password");
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
                alert("Missing name or Password");
            } else {
                setErrMsg('Login Failed');
                alert("연결이 원활하지 않습니다. 다시 시도해 주세요.");
            }
        } 
    }


    return(
        <div className="forgotIdContainer">
            <div className="forgotIdBox-1">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="resgisteredemail-tab" data-bs-toggle="tab" data-bs-target="#resgisteredemail" type="button" role="tab" aria-controls="resgisteredemail" aria-selected="true">이메일로 찾기</button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button className="nav-link" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">휴대폰으로 찾기</button>
                    </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="resgisteredemail" role="tabpanel" aria-labelledby="home-tab">
                        <div>
                            <form onSubmit={handleEmailSubmit}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
                                        <label htmlFor="name">이름:</label>
                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="name">아이디:</label>
                                        <input type="text" id="username" value={username} placeholder="user ID" onChange={e => setUsername(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="email">등록한 이메일 주소:</label>
                                        <input type="email" id="email" value={email} placeholder="registered email address" onChange={e => setEmail(e.target.value)} required />
                                    </div>
                                    {/* <div>
                                        <Link to="/forgotid">아이디 찾기</Link>
                                    </div> */}
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"150px", padding:"7px 15px"}}>인증번호 받기</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div>
                            <form onSubmit={verifyCode}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
                                        <label htmlFor="name">인증번호:</label>
                                        <input type="text" id="code" value={code} placeholder="verification code" onChange={e => setCode(e.target.value)} required />
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"120px", padding:"7px 15px"}}>확인</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="profile-tab">
                        <div>
                            <form onSubmit={handleMobileSubmit}>
                                <div className="inputListContainer">
                                    <div className="labeledItem">
                                        <label htmlFor="name">이름:</label>
                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="name">아이디:</label>
                                        <input type="text" id="username" value={username} placeholder="user ID" onChange={e => setUsername(e.target.value)} required />
                                    </div>
                                    <div className="labeledItem">
                                        <label htmlFor="email">등록한 휴대폰 번호:</label>
                                        <input type="phone" id="mobile" value={mobile} placeholder="registered mobile number" onChange={e => setMobile(e.target.value)} required />
                                    </div>
                                    <div>
                                        <Link to="/forgotid">아이디 찾기</Link>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <button className="button" style={{width:"120px", padding:"7px 15px"}}>다음 단계</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ForgotPwdFirst;