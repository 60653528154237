import React from 'react';
import SideMenuCategory from './SideMenuCategory';
import '../pages/mathsubject.css';

const SideBar = ({problemCate, categories, problemID, topCategory, topCategoryTitle, isSample}) => {
    return(
        <div className="subjectsidebar-1">
            <h4 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px", marginLeft:"5px"}}>
                {topCategoryTitle}
            </h4>
            {categories&&categories.map((category) =>{
                return(
                    <div key={category.categoryCode}>
                        <div>
                            <SideMenuCategory 
                                category={category}
                                problemCate={problemCate} 
                                problemID={problemID} 
                                topCategory={topCategory}
                                topCategoryTitle={topCategoryTitle}
                                isSample={isSample}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SideBar;
