import React, {useState, useEffect, useRef, useCallback} from "react";
import { Link } from "react-router-dom";
import useGetSubjectList from "../hooks/useGetSubjectList";
import SideMenu from "../components/SideMenu";
import "./home.css"
import Footer from "../components/Footer";

const MathSubjectList = () => {
    const [pageNumber, setPageNumber] = useState(1)
    const { loading, error, hasMore, subjectList } = useGetSubjectList(pageNumber)
    const [randomString, setRandomString] = useState();
    
    const observer = useRef()

    const lastProblemElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
            })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    useEffect(() => {
        const randomAlphabetString = () => {
            const letters = '1234567890';
            let randomStringl = '';
            for (let i = 0; i < 39; i++) {
                randomStringl += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString(randomStringl);
          };
          randomAlphabetString();
    },[])

    return(
        <div className="containerHome">
            <div className="boxHome-1">
                <p className="title">체계적으로 도전하기</p>
            </div>
            <div className="boxHome-2">
                <div className="containerList">
                    <div className="containerListItem">
                        <div className="subjectCards">
                            {console.log("subjectList: ",subjectList)}
                            {subjectList.map((subject, index) => {
                                if(subjectList.length === index+1) {
                                    return (
                                        <div ref={lastProblemElementRef} key={subject.id} className="subjectCard">
                                            <h4>{subject.goodApproachTitle}</h4>
                                            <p>{subject.goodApproachDescription}</p>
                                            <p>{subject.publicationDate}</p>
                                            <Link 
                                                to={`/mathsubject/${randomString}`}
                                                state={{
                                                    "subjectCode": subject.subjectCode,
                                                    "problemCode": subject.firstProblemCode,
                                                    "subjectTitle": subject.goodApproachTitle
                                                }}
                                            >
                                                참여하기...
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={subject.topCategory} className="subjectCard">
                                            <h4>{subject.goodApproachTitle}</h4>
                                            <p>{subject.goodApproachDescription}</p>
                                            <p>{subject.publicationDate}</p>
                                            <Link 
                                                to={`/mathsubject/${randomString}`}
                                                state={{
                                                    "subjectCode": subject.subjectCode,
                                                    "problemCode": subject.firstProblemCode,
                                                    "subjectTitle": subject.goodApproachTitle
                                                }}
                                            >
                                                참여하기...
                                            </Link>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>{loading && 'Loading...'}</div>
                        <div>{error && 'Error'}</div>
                    </div>
                    <div className="containerListItem">
                        <div className="rightSideMenuList">
                            <div className="rightSideMenuListItem">
                                <SideMenu />
                            </div>
                            <div className="rightSideMenuListItem">
                                풀지 못했던 문제
                            </div>
                            <div className="rightSideMenuListItem">
                                부담없이 도전하기
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxListFooter-6">
                <Footer/>
            </div>
        </div>
    );
}

export default MathSubjectList;