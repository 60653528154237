import React, { useEffect, useState } from "react";
import './signup.css';
import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import { axiosNode } from "../api/axiosapi2";

const PaymentSecond = () => {
	const {product_code} = useLocation().state;
    const axiosPrivate = useAxiosPrivate();
    const [premiumMembershipAgreementO, setPremiumMembershipAgreementO] = useState([]);
    const [agreePremiumMembershipAgreement, setAgreePremiumMenbershipAgreement] = useState(false);
    const [paymentData, setPaymentData] = useState({
        oid: '',
        buyername: '',
        buyeremail: '',
        buyertel: '',
        goodname: '',
        price: '',
        mid: '',
        timestamp: '',
        mKey: '',
        use_chkfake: '',
        signature: '',
        verification: '',
    })
    const [productDescription, setProductDescription] = useState('');
    const [formattedPrice, setFormattedPrice] = useState('')
    const [errPP, setErrPP] = useState('');
    const [errFA, setErrFA] = useState('');
    const [count1, setCount1] = useState(1);
    const [count2, setCount2] = useState(1);
    const navigateto = useNavigate();
    const location = useLocation();
    const {auth} = useAuth();
    // const [SendPayForm_id, setSendPayForm_id] = useState({
    //     version: '',
    //     gopaymethod: '',
    //     mid: '',
    //     oid: '',
    //     goodname: '',
    //     price: '',
    //     timestamp: '',
    //     use_chkfake: '',
    //     signature: '',
    //     verification: '',
    //     mKey: '',
    //     currency: '',
    //     buyername: '',
    //     buyeremail: '',
    //     buyertel: '',
    //     returnUrl: '',
    //     closeUrl: '',
    //     acceptmethod: '',
    // });

    const toggleAgreePremiumMenbership = () => {
        setAgreePremiumMenbershipAgreement(prev => !prev)
    }

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     // script.src = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js"; // 테스트
    //     script.src = "https://stdpay.inicis.com/stdjs/INIStdPay.js"; // 실제 운영
    //     script.language = "javascript";
    //     script.type = "text/javascript";
    //     script.charset = "UTF-8";
    //     document.body.appendChild(script);
    
    //     return () => {
    //       document.body.removeChild(script); // 컴포넌트가 언마운트될 때 스크립트 제거
    //     };
    // }, [count2]);

    useEffect(() => {
        const controller = new AbortController();

        const fetchPremiumMembership = async() => {
            try {
                const response = await axiosPrivate.get('/pay/requiredata', {
                    params: {
                        product_code: product_code
                    },
                    signal: controller.signal
                })
                setPaymentData(prevState => ({
                    ...prevState,
                    oid: response.data.orderCode,
                    buyername: response.data.name,
                    buyeremail: response.data.email,
                    buyertel: response.data.mobile,
                    goodname: response.data.productName,
                    price: response.data.price
                }));
                setProductDescription(response.data.productDescription);
                const formattedPrice = new Intl.NumberFormat('en-US').format(response.data.price);
                setFormattedPrice(formattedPrice);
                response.data && setCount1(count1+1);
                const premiumMembershipAgreementO = response.data && response.data.content.split("|");
                setPremiumMembershipAgreementO(premiumMembershipAgreementO);
            } catch (err) {
                setErrFA(err.message);
                (auth?.accessToken === 'notoken' || !auth?.accessToken) && err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchPremiumMembership();

        return () => controller.abort();
    },[])

    // useEffect(() => {
    //     // const controller = new AbortController();

    //     const fetchRequestPayment = async() => {
    //         try {
    //             const response2 = await axiosNode.get('/', {
    //                 params: {
    //                     oid: paymentData.oid,
    //                     price: paymentData.price
    //                 }
    //             })
    //             setPaymentData(prevState => ({
    //                 ...prevState,
    //                 mid: response2.data.mid,
    //                 timestamp: response2.data.timestamp,
    //                 mKey: response2.data.mKey,
    //                 use_chkfake: response2.data.use_chkfake,
    //                 signature: response2.data.signature,
    //                 verification: response2.data.verification
    //             }));
    //             response2.data && setCount2(count2+1);
    //             // const mid = response.data && response.data.mid;
    //             // setMid(mid);
    //             // const timestamp = response.data && response.data.timestamp;
    //             // setTimestamp(timestamp);
    //             // const mKey = response.data && response.data.mKey;
    //             // setMKey(mKey);
    //             // const use_chkfake = response.data && response.data.use_chkfake;
    //             // setUse_chkfake(use_chkfake);
    //             // const signature = response.data && response.data.signature;
    //             // setSignature(signature);
    //             // const verification = response.data && response.data.verification;
    //             // setVerification(verification);
    //         } catch (err) {
    //             setErrPP(err.message);
    //             if (err.response2) {
    //                 console.log(err.response2.data);
    //                 console.log(err.response2.status);
    //                 console.log(err.response2.headers);
    //             } else {
    //                 console.log(`Error: ${err.message}`);
    //             }
    //         }
    //     }

    //     fetchRequestPayment();

    //     // return () => controller.abort();
    // },[count1])

    


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPaymentData({
          ...paymentData,
          [name]: value
        });
    }

    console.log(paymentData);

    // paybtn 함수를 정의합니다.

    const paybtn = () => {
        if (agreePremiumMembershipAgreement) {

            // 모든 데이터를 받은 상태에서 결제를 요청하는 예문 (미완성)
            // console.log(paymentData);
            // console.log("mid:",paymentData.mid);
            // const form = document.createElement('form');
            // form.setAttribute('method', 'POST');
            // form.setAttribute('accept-charset', 'euc-kr');

            // form.setAttribute('action', 'https://stdpay.inicis.com/stdjs/INIStdPayStart.js');
            // form.innerHTML = `
            //     <input type="hidden" name="version" value="1.0" />
            //     <input type="hidden" name="gopaymethod" value="Card:DirectBank:VBank:HPP" />
            //     <input type="hidden" name="mid" value="${paymentData.mid}" />
            //     <input type="hidden" name="oid" value="${paymentData.oid}" />
            //     <input type="hidden" name="goodname" value="${paymentData.goodname}" />
            //     <input type="hidden" name="price" value="${paymentData.price}" />
            //     <input type="hidden" name="timestamp" value="${paymentData.timestamp}" />
            //     <input type="hidden" name="use_chkfake" value="${paymentData.use_chkfake}" />
            //     <input type="hidden" name="signature" value="${paymentData.signature}" />
            //     <input type="hidden" name="verification" value="${paymentData.verification}" />
            //     <input type="hidden" name="mKey" value="${paymentData.mKey}" />
            //     <input type="hidden" name="currency" value="WON" />
            //     <input type="hidden" name="buyername" value="${paymentData.buyername}" />
            //     <input type="hidden" name="buyertel" value="${paymentData.buyertel}" />
            //     <input type="hidden" name="buyeremail" value="${paymentData.buyeremail}" />
            //     <input type="hidden" name="returnUrl" value="https://tiffmath.com/payment/inicisreturn" />
            //     <input type="hidden" name="closeUrl" value="https://tiffmath.com/payment/inicisclose" />
            //     <input type="hidden" name="acceptmethod" value="HPP(1):va_receipt:below1000:centerCd(Y)" />
            // `;
            // document.body.appendChild(form);
            // // INIStdPay 결제 모듈 실행
            // window.INIStdPay.pay(form);


            // 버튼의 form 데이터를 이용해 결제 요청하는 다른 예 (미완성)
            // if (window.INIStdPay) {
            //     window.INIStdPay.pay('SendPayForm_id');
            // } else {
            //     console.error('INIStdPay is not loaded yet');
            // }

            const url = 'https://tiffmath.com/node/'; // 새 창에서 열 URL
            const params = new URLSearchParams({
                oid: paymentData.oid,
                buyername: paymentData.buyername,
                buyeremail: paymentData.buyeremail,
                buyertel: paymentData.buyertel,
                goodname: paymentData.goodname,
                price: paymentData.price
            });

            const fullUrl = `${url}?${params.toString()}`; // URL에 파라미터 추가
            window.open(fullUrl, '_blank', 'noopener,noreferrer');
        } else {
            alert("유료서비스 이용약관에 동의해야 결제할 수 있습니다.");
        }
    };


    // const handlePayment = async (e) => {
    //     e.preventDefault();

    //     if (agreePremiumMembershipAgreement) {
    //         const form = document.createElement('form');
    //         form.method = 'POST';
    //         form.action = 'https://stdpay.inicis.com/stdjs/INIStdPay.js';
    //         form.target = '_blank';

    //         Object.keys(paymentData).forEach(key => {
    //             const input = document.createElement('input');
    //             input.type = 'hidden';
    //             input.name = key;
    //             input.value = paymentData[key];
    //             form.appendChild(input);
    //         });

    //         document.body.appendChild(form);
    //         form.submit();
    //         document.body.removeChild(form);
    //     } else {
    //         alert("유료서비스 이용약관에 동의해야 결제할 수 있습니다.");
    //     }
        
    // }

    
    return(
        <div className="agreementContainer">
            <div className="agreementBox">
                {/* <div>
                    <h4>{paymentData.goodname}</h4>
                    <p>{productDescription}</p>
                    <p>결제금액: {formattedPrice}원</p>
                    <p>구 매 자: {paymentData.buyername}</p>
                    <p>이 메 일: {paymentData.buyeremail}</p>
                    <p>전화번호: {paymentData.buyertel}</p>
                </div> */}
                <h4>유료서비스 이용약관 동의 및 결제</h4>
                <br></br>
                <div>
                    <h5>유료서비스 이용약관</h5>
                    <div style={{width: "600px", height: "300px", overflow: "scroll", border: "1px solid black"}}>{premiumMembershipAgreementO && premiumMembershipAgreementO.map(item => (<div><p>{item}</p></div>))}</div>
                    <input type="checkbox" id="premiumMem" onChange={toggleAgreePremiumMenbership} checked={agreePremiumMembershipAgreement} />
                    <label htmlFor="premiumMem">위의 유료서비스 이용약관에 동의합니다.</label>
                </div>
                <br></br>
                <div>
                    <h5>구매상품: {paymentData.goodname}</h5>
                    <p>{productDescription}</p>
                    <p>주문번호: {paymentData.oid}</p>
                    <p>결제금액: {formattedPrice}원</p>
                </div>
                <br></br>
                <h5>구매자 정보 확인</h5>
                <div style={{marginTop:"25px", textAlign:"center"}}>
                    {/* <form id="SendPayForm_id" method="post">
                        <div className="row g-3 justify-content-between" style={{ '--bs-gutter-x': '0rem' }}>
                            <input type="hidden" name="version" value="1.0" />
                            <input type="hidden" name="gopaymethod" value="Card:DirectBank:VBank:HPP" />
                            <input type="hidden" name="mid" value={paymentData.mid} />
                            <input type="hidden" name="oid" value={paymentData.oid} />
                            <input type="hidden" name="goodname" value={paymentData.goodname} />
                            <input type="hidden" name="price" value={paymentData.price} />
                            <input type="hidden" name="timestamp" value={paymentData.timestamp} />
                            <input type="hidden" name="use_chkfake" value={paymentData.use_chkfake} />
                            <input type="hidden" name="signature" value={paymentData.signature} />
                            <input type="hidden" name="verification" value={paymentData.verification} />
                            <input type="hidden" name="mKey" value={paymentData.mKey} />
                            <input type="hidden" name="currency" value="WON" />
                          
                            <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                                구매자
                            </label>
                            <label className="col-10 col-sm-9 input">
                                <input type="text" name="buyername" defaultValue={paymentData.buyername} />
                            </label>

                            <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                                전화번호
                            </label>
                            <label className="col-10 col-sm-9 input">
                                <input type="text" name="buyertel" defaultValue={paymentData.buyertel} />
                            </label>

                            <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                                이메일
                            </label>
                            <label className="col-10 col-sm-9 input">
                                <input type="text" name="buyeremail" defaultValue={paymentData.buyeremail} />
                            </label>

                            <input type="hidden" name="returnUrl" value="https://tiffmath.com/INIstdpay_pc_return.ejs" />
                            <input type="hidden" name="closeUrl" value="https://tiffmath.com/close" />
                            <input
                                type="hidden"
                                name="acceptmethod"
                                value="HPP(1):va_receipt:below1000:centerCd(Y)"
                            />
                        </div>
                    </form> */}
                    <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                        구매자
                    </label>
                    <label className="col-10 col-sm-9 input">
                        <input type="text" name="buyername" value={paymentData.buyername} onChange={handleChange}  />
                    </label>

                    <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                        전화번호
                    </label>
                    <label className="col-10 col-sm-9 input">
                        <input type="text" name="buyertel" value={paymentData.buyertel} onChange={handleChange}  />
                    </label>

                    <label className="col-10 col-sm-2 input param" style={{ border: 'none' }}>
                        이메일
                    </label>
                    <label className="col-10 col-sm-9 input">
                        <input type="text" name="buyeremail" value={paymentData.buyeremail} onChange={handleChange}  />
                    </label>
                    <button onClick={paybtn} className="button" style={{ marginTop: '50px' }}>
                        결제 요청
                    </button>
                    {/* <div>
                        <button 
                            className="button"
                            onClick={handlePayment}
                            // onClick={() => agreePremiumMembershipAgreement 
                            //     ? {handlePayment} 
                            //     : alert("유료서비스 이용약관에 동의해야 결제할 수 있습니다.")
                            // }
                        >
                            결제
                        </button>
                        <button 
                            className="button"
                            onClick={() => navigateto(-1)}
                        >
                            취소
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default PaymentSecond;