import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import useAuth from '../hooks/useAuth';
// import axiosapi from "../api/axiosapi";
import '../App.css';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const TodayProblemModifyMyProblemDesignList = () => {
    // const {auth} = useAuth();
    const navigateto = useNavigate();
    const {todayProblemList, todayProblem} = useLocation().state;
    const [canvasList, setCanvasList] = useState([]);
    const [selectedCanvas, setSelectedCanvas] = useState();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    useEffect (() => {
        let isMounted = true;
        const controller = new AbortController();

        const getProblemCanvasList = async () => {
            try {
                const response = await axiosPrivate.get('/canvas/problem/user/list', {
                    params: {
                        problemCode: todayProblem.problemCode
                    },
                    signal: controller.signal
                })
                isMounted && response.data && setCanvasList(response.data);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

        getProblemCanvasList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [todayProblem])

    if (canvasList.length === 0) {
        return (
            <div className="problem">
                <p>"그림을 그린 기록이 없습니다."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                        "todayProblemList": todayProblemList,
                        "todayProblem": todayProblem
                    }})}
                >
                    문제로 돌아가기
                </button>
            </div>
        )
    }

    return (
        <div className="problem">
            <h4>{todayProblem.todayProblemTitle}의 그림</h4>
            <div>
                <select value={selectedCanvas} style={{height:"36px"}} onChange={(e) => setSelectedCanvas(e.target.value)}>
                    <option value>그림 제목을 선택하세요.</option>
                    {canvasList.map(canvas => {
                        return <option value={canvas.canvasCode} key={canvas.canvasCode}>
                            {canvas.canvasTitle}
                        </option>
                    })}
                </select>
                <button 
                    className='button' 
                    style={{marginLeft:"10px"}}
                    onClick={() => selectedCanvas
                        ? navigateto(`/todayproblem/my/design/modify/problem/${selectedCanvas}`, {state: {
                            "todayProblemList": todayProblemList,
                            "todayProblem": todayProblem,
                            "canvasList": canvasList
                        }})
                        : alert("그림 제목을 선택하세요.")
                    }
                >
                    그림 보기
                </button>
            </div>
        </div>
    )
}

export default TodayProblemModifyMyProblemDesignList;