import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import ViewMyAnswer from '../components/ViewMyAnswer';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }

    contentArray.push("의 풀이 조회");
  
    return <div>{contentArray}</div>;
};

const TodayProblemMyS = ()=> {
    const navigateto = useNavigate();
    const {todayProblemList, problem, todayProblem, useStat, isFirst, isLast, prevProblem, nextProblem, proID} = useLocation().state;
    const {isMyAnswer, latestAnswer, myAnswerList, errMyAns} = ViewMyAnswer({"problemCode": todayProblem.problemCode});
    const [selectedAnswer, setSelectedAnswer] = useState();
    const location = useLocation();

    if (isMyAnswer === false) {
        return(
            errMyAns 
                ? navigateto("/login", {state:{"from": location}}, {replace: true})
                : <div className="problem">
                    <p>"문제를 푼 기록이 없습니다."</p>
                    <button 
                        className='button'
                        onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                            "todayProblemList": todayProblemList,
                            "todayProblem": todayProblem
                        }})}
                    >
                        문제로 돌아가기
                    </button>
                </div>
        )
    }
    
    return(
        <div className="problem">
            <div>
                <div style={{fontSize:24,fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                <div>
                    <select value={selectedAnswer} style={{height:"36px"}} onChange={(e) => setSelectedAnswer(e.target.value)}>
                        <option value>문제를 푼 때를 선택하세요.</option>
                        {myAnswerList.map(myAns => {
                            return <option value={myAns.answerMark} key={myAns.answerMark}>
                                {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                            </option>
                        })}
                    </select>
                    <button 
                        className='button' 
                        style={{marginLeft:"10px"}}
                        onClick={() => selectedAnswer 
                            ? navigateto(`/todayproblem/my/${selectedAnswer}`, {state: {
                                "todayProblemList": todayProblemList,
                                "problem": problem,
                                "todayProblem": todayProblem,
                                "myAnswerList": myAnswerList,
                                "useStat": useStat,
                                "isFirst": isFirst,
                                "isLast": isLast,
                                "prevProblem": prevProblem,
                                "nextProblem": nextProblem,
                                "proID": proID
                            }})
                            : alert("문제 풀이 시간을 선택하세요.")
                        }
                    >
                        풀이 보기
                    </button>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default TodayProblemMyS;