import { React, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const ProblemStatDescription = (props) => {
    const { auth } = useAuth();
    const [problemDescriptionView, setProblemDescriptionView] = useState([]);
    // const sentence = "정사각형의 안쪽으로 넓이가 정사각형의 <InlineMath>\\frac{9}{29}</InlineMath>인 정사각형을 <InlineMath>x^2 + y^2 </InlineMath> 그리세요.";

    // const containerStyle = {
    //     whiteSpace: 'nowrap',
    //     maxWidth: '1520px',
    // };

    useEffect (() => {
        let tempView = props.problemDescription && props.problemDescription.split('<br>');
        setProblemDescriptionView(tempView);
    }, [props.problemDescription])

    return(
        <div>
            {<div style={{fontSize:24,fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>
                <div>
                    {props.problemTitle && splitContent(props.problemTitle)}
                </div>
            </div>}
            {<div style={{marginLeft:"5px"}}>
                {problemDescriptionView && problemDescriptionView.map(view => (
                    <div>
                        {splitContent(view)}
                    </div>
                ))}
            </div>}
            {/* <div style={containerStyle}>
                {splitContent(sentence)}
            </div> */}
            <div style={{marginLeft:"5px"}}>
                {props.useStat
                    ? props.useStat.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : props.useStat.successfulUserNum === 0 
                            ? `${props.useStat.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${props.useStat.triedUsersNum}명이 도전해 ${props.useStat.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(props.useStat.successfulUserNum/props.useStat.triedUsersNum*100).toFixed(2)}%의 사용자와 같은 자리를 차지하세요.`
                    : "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                }
            </div>
        
            <div style={{marginLeft:"5px"}}>
                {props.useStat.myTryNum && props.useStat.myTryNum !== 0
                    ? <div>회원님은 {props.useStat.myTryNum}번을 시도해 {props.useStat.mySuccessNum}번 문제를 풀어냈습니다.</div>
                    : null
                }
                {/* {props.trialno && <div>{auth?.username}님은 {props.trialno.trialno1}번을 시도해 {props.trialno.trialno2}번 문제를 풀어냈습니다. 
                마지막 시도는 {props.trialno.trialno4 === 1 ? (props.trialno.trialno5 <= 1 ? "틀리지 않고 바로 문제를 해결했습니다" : `${props.trialno.trialno5} 번만에 문제를 해결했습니다.`) : `${props.trialno.trialno5} 번을 시도했으나 문제를 풀지 못하고 마쳤습니다.`}</div>} */}
                <br/>
            </div>
        </div>
    )
}

export default ProblemStatDescription;